import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@northvolt/ui'
import type { ChangeDetectionResult } from 'client/model'
import { dateStringDisplayFormat } from 'components/Utils'
import type React from 'react'
import ChangeDetectionResultPlot from './ChangeDetectionResultPlot'

interface ChangeDetectionResultAccordionProps {
  changeDetectionResult: ChangeDetectionResult
  tableName: string
  showTrendline: boolean
  showOutliers: boolean
}

const ChangeDetectionResultAccordion: React.FC<
  ChangeDetectionResultAccordionProps
> = ({ changeDetectionResult, tableName, showTrendline, showOutliers }) => {
  const changePointSummary = () => {
    if (changeDetectionResult.change_points?.length === 0) {
      return (
        <Typography variant='subtitleSmall'>No change points found</Typography>
      )
    }
    if (changeDetectionResult.change_points?.length === 1) {
      return (
        <Typography variant='subtitleSmall'>
          Found <strong>1</strong> change point
        </Typography>
      )
    }
    return (
      <Typography variant='subtitleSmall'>
        Found <strong>{changeDetectionResult.change_points.length}</strong>{' '}
        change points
      </Typography>
    )
  }

  const outlierSummary = () => {
    if (changeDetectionResult.outliers?.length === 0) {
      return <Typography variant='subtitleSmall'>No outliers found</Typography>
    }
    if (changeDetectionResult.outliers?.length === 1) {
      return (
        <Typography variant='subtitleSmall'>
          Found <strong>1</strong> outlier
        </Typography>
      )
    }
    return (
      <Typography variant='subtitleSmall'>
        Found <strong>{changeDetectionResult.outliers.length}</strong> outliers
      </Typography>
    )
  }

  const changePointDetails = (
    <Box>
      {changeDetectionResult.change_points?.map(cp => (
        <Chip
          key={cp.id}
          label={dateStringDisplayFormat(cp.changed_at)}
          sx={{ m: 0.3 }}
          size='small'
        />
      ))}
    </Box>
  )

  return (
    <Accordion
      disableGutters
      sx={{ width: '100%' }}
      key={changeDetectionResult.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction='row' spacing={1} alignItems='baseline'>
          <Typography variant='subtitleMedium' key={1}>
            <strong>{changeDetectionResult.attribute.column_name}</strong>
          </Typography>
          {changePointSummary()}•{outlierSummary()}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Grid container spacing={1} columns={12}>
          {changeDetectionResult.change_points?.length > 0 &&
            changePointDetails}
          <Grid xs={12} />
          <Grid xs={4}>
            <Button size='small' disabled>
              Create Sample
            </Button>
          </Grid>
          <Grid xs={12}>
            <Divider sx={{ m: 1 }} />
          </Grid>
          <Grid xs={12}>
            <ChangeDetectionResultPlot
              changeDetectionResult={changeDetectionResult}
              tableName={tableName}
              showTrendline={showTrendline}
              showOutliers={showOutliers}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ChangeDetectionResultAccordion
