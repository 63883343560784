import type { FilterOptionsState } from '@mui/material'
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Tooltip,
} from '@northvolt/ui'
import { useDebounce } from '@uidotdev/usehooks'
import type { MaterialType, Sample, SampleLight } from 'client/model'
import { useSearchSamplesByText } from 'client/wattson-client'
import React, { useEffect } from 'react'
import SampleChip from './SampleChip'
import SampleSelectItem from './SampleSelectItem'

interface SampleSearchAndSelectProps {
  placeholder?: string
  suggestedSamples?: SampleLight[]
  selectedSamples: SampleLight[]
  setSelectedSamples: (samples: SampleLight[]) => void
  grain: MaterialType
  disabled?: boolean
  multiple?: boolean
  label?: string
  tooltip?: string
}

const SampleSearchAndSelect: React.FC<SampleSearchAndSelectProps> = ({
  placeholder,
  suggestedSamples,
  selectedSamples,
  setSelectedSamples,
  grain,
  disabled,
  multiple,
  label = 'Select Samples',
  tooltip = 'Free text search for samples',
}) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const [showPlaceholder, setshowPlaceholder] = React.useState<boolean>(true)
  const debouncedSearchText = useDebounce(searchText, 300)
  const sampleLoader = useSearchSamplesByText()

  useEffect(() => {
    if (debouncedSearchText) {
      sampleLoader.mutate({
        params: { text: debouncedSearchText, grain: grain },
      })
    } else {
      sampleLoader.reset()
    }
  }, [debouncedSearchText])

  useEffect(() => {
    if (selectedSamples.length === 0) {
      sampleLoader.reset()
      setshowPlaceholder(true)
    } else {
      setshowPlaceholder(false)
    }
  }, [selectedSamples])

  const getOptions = () => {
    const searchOptions = sampleLoader.data?.data.items
      ? sampleLoader.data.data.items
      : suggestedSamples || []
    const selectedOptions = selectedSamples
      ? selectedSamples
      : searchOptions.slice(0, 5)
    const distinctOptions = new Set([...searchOptions, ...selectedOptions])
    return Array.from(distinctOptions)
  }

  const getValue = () => {
    if (multiple) {
      return selectedSamples
    }
    return selectedSamples?.length > 0 ? selectedSamples[0] : null
  }

  const handleChange = (_: any, newValue: any) => {
    if (multiple) {
      setSelectedSamples(newValue)
    } else {
      setSelectedSamples(newValue ? [newValue] : [])
    }
  }

  const handleInputChange = (
    _event: React.SyntheticEvent,
    newInputValue: string,
  ) => {
    setSearchText(newInputValue)
  }

  return (
    <Tooltip placement='top' title={tooltip}>
      <Autocomplete
        size='small'
        options={getOptions()}
        filterOptions={(options: Sample[], _: FilterOptionsState<any>) =>
          options
        } // Don't do any additional filtering in the frontend
        loading={sampleLoader.isLoading}
        value={getValue()}
        onChange={(_: any, newValue: any) => handleChange(_, newValue)}
        onInputChange={handleInputChange}
        getOptionLabel={(sample: SampleLight) => sample.name}
        isOptionEqualToValue={(sample: any, other: any) =>
          sample.id === other.id
        }
        renderOption={(props, sample: SampleLight) => {
          const myProps: any = props // annoying...
          const { key, ...rest } = myProps
          return (
            <SampleSelectItem
              sample={sample}
              listItemProps={rest}
              searchText={searchText}
              key={sample.id}
            />
          )
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={showPlaceholder ? placeholder : ''}
            variant='outlined'
            InputLabelProps={{ shrink: placeholder ? true : undefined }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {sampleLoader.isLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(value: any, getTagProps: any) =>
          value.map((sample: SampleLight, index: number) => (
            <SampleChip
              sample={sample}
              key={index}
              chipProps={getTagProps({ index })}
            />
          ))
        }
        autoHighlight
        autoComplete
        fullWidth
        filterSelectedOptions
        noOptionsText='Empty or invalid search query (please type something).'
        disabled={disabled}
        multiple={!!multiple}
      />
    </Tooltip>
  )
}

export default SampleSearchAndSelect
