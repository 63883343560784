//@ts-nocheck filter is undefined
import BalanceIcon from '@mui/icons-material/Balance'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import {
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material'
import { Box, Grid, Loader, Stack, Tooltip } from '@northvolt/ui'
import { FilterType } from 'client/model'
import React, { useEffect } from 'react'
import AttributeSearchAndSelect from '../Attribute/AttributeSearchAndSelect'
import AttributeSelector from '../Attribute/AttributeSelector'
import type { SelectableAttributeCollection } from '../DataLoader/DataLoaderTypes'

import type {
  AdhocFilter,
  Attribute,
  AttributeCollectionLight,
  AttributeLight,
  MaterialType,
} from 'client/model'

import { useReadAttributeStatistics } from 'client/wattson-client'
import { isDateOrTime, isNumeric } from 'components/Utils'
import DatetimeRangeFilterControl from './DatetimeRangeFilterControl'
import EqualityFilterControl from './EqualityFilterControl'
import NumberRangeFilterControl from './NumberRangeFilterControl'

interface FilterAttributeCollectionProps {
  filter?: AdhocFilter
  setFilter: (adhocFilter: AdhocFilter) => void
  attributeCollections?:
    | SelectableAttributeCollection[]
    | AttributeCollectionLight[]
    | undefined
  grain?: MaterialType
}

const FilterAttributeCollectionWidget: React.FC<
  FilterAttributeCollectionProps
> = ({ filter, setFilter, attributeCollections, grain }) => {
  const theme = useTheme()
  const [selectedAttribute, setSelectedAttribute] =
    React.useState<AttributeLight>(undefined)
  const attrStatsLoader = useReadAttributeStatistics(selectedAttribute?.id)

  const handleFilterTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newFilterType: string | null,
  ) => {
    if (newFilterType !== null) {
      setFilter({
        attribute: filter.attribute,
        attribute_id: filter.attribute_id,
        type: newFilterType as FilterType,
        multiple: filter.multiple,
      })
    }
  }

  useEffect(() => {
    if (attrStatsLoader.isSuccess && attrStatsLoader?.data?.data) {
      const metadata = attrStatsLoader.data.data
      // getAvailableFilterTypes(selectedAttribute?.data_type)
      setFilter({
        ...filter,
        attribute: metadata.attribute,
        attribute_id: metadata.attribute.id,
      })
    }
  }, [attrStatsLoader.isSuccess])

  const getFilterControl = () => {
    if (selectedAttribute && attrStatsLoader.isLoading) {
      return (
        <Box display='flex' justifyContent='center'>
          <Loader type='linear' size='small' />
        </Box>
      )
    }
    if (attrStatsLoader.isSuccess && attrStatsLoader?.data?.data) {
      const metadata = attrStatsLoader.data.data
      if (
        filter.type === FilterType.equal ||
        filter.type === FilterType.not_equal
      ) {
        return (
          <EqualityFilterControl
            attributeStatistics={metadata}
            filter={filter}
            setFilter={setFilter}
          />
        )
      }
      if (filter.type === FilterType.number_range) {
        return (
          <NumberRangeFilterControl
            attributeStatistics={metadata}
            filter={filter}
            setFilter={setFilter}
          />
        )
      }
      if (filter.type === FilterType.datetime_range) {
        return (
          <DatetimeRangeFilterControl
            attributeStatistics={metadata}
            filter={filter}
            setFilter={setFilter}
          />
        )
      }
      return <></>
    }
  }

  const getAttributeSelector = () => {
    if (attributeCollections !== undefined) {
      return (
        <AttributeSelector
          label='Attribute'
          attributeCollections={attributeCollections}
          selectedAttributes={selectedAttribute ? [selectedAttribute] : []}
          setSelectedAttributes={attributes =>
            setSelectedAttribute(attributes[0])
          }
          multiple={false}
          disabled={attributeCollections?.length === 0}
          tooltip='Select an attribute to filter on'
        />
      )
    }
    return (
      <AttributeSearchAndSelect
        label='Attribute'
        selected={selectedAttribute ? [selectedAttribute] : []}
        setSelected={attributes => setSelectedAttribute(attributes[0])}
        multiple={false}
        tooltip='Select an attribute to filter on'
        grain={grain}
      />
    )
  }

  return (
    <Grid
      container
      spacing={1}
      columns={12}
      sx={{ m: 1, p: 1, backgroundColor: theme.palette.grey[900] }}
      border={0.5}
      borderRadius={1}
      borderColor={theme.palette.grey[700]}>
      <Grid xs={12}>
        <Tooltip title='Remove Filter' placement='top'>
          <IconButton
            size='small'
            onClick={() => {
              //@ts-ignore - this should not work
              setFilter(undefined)
            }}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid xs={12}>{getAttributeSelector()}</Grid>
      <Grid xs={12}>
        {selectedAttribute && (
          <Stack>
            <Typography id='filter-type' variant='captionSmall'>
              Filter Type
            </Typography>
            <ToggleButtonGroup
              value={filter?.type}
              disabled={attrStatsLoader.isLoading}
              exclusive
              size='small'
              onChange={handleFilterTypeChange}
              title='Filter Type'>
              {getAvailableFilterTypes(selectedAttribute).map(filterType =>
                getFilterTypeButton(filterType),
              )}
            </ToggleButtonGroup>
          </Stack>
        )}
      </Grid>
      <Grid xs={12}>{getFilterControl()}</Grid>
    </Grid>
  )
}

const getAvailableFilterTypes = (attribute: Attribute) => {
  const filters: FilterType[] = [
    FilterType.equal,
    FilterType.not_equal,
    FilterType.null,
    FilterType.not_null,
  ]
  if (isNumeric(attribute)) {
    filters.unshift(FilterType.number_range)
  } else if (isDateOrTime(attribute)) {
    filters.unshift(FilterType.datetime_range)
  }
  return filters
}

function getFilterTypeButton(filterType: FilterType) {
  switch (filterType) {
    case FilterType.equal:
      return (
        <ToggleButton key={filterType} value={filterType}>
          <BalanceIcon />
          <Typography variant='captionSmall' sx={{ pl: 1 }}>
            Equal
          </Typography>
        </ToggleButton>
      )
    case FilterType.not_equal:
      return (
        <ToggleButton key={filterType} value={filterType}>
          <CancelIcon />
          <Typography variant='captionSmall' sx={{ pl: 1 }}>
            Not Equal
          </Typography>
        </ToggleButton>
      )
    case FilterType.number_range:
      return (
        <ToggleButton key={filterType} value={filterType}>
          <CompareArrowsIcon />
          <Typography variant='captionSmall' sx={{ pl: 1 }}>
            In Range
          </Typography>
        </ToggleButton>
      )
    case FilterType.datetime_range:
      return (
        <ToggleButton key={filterType} value={filterType}>
          <CalendarMonthIcon />
          <Typography variant='captionSmall' sx={{ pl: 1 }}>
            In Range
          </Typography>
        </ToggleButton>
      )
    case FilterType.null:
      return (
        <ToggleButton key={filterType} value={filterType}>
          <NotInterestedIcon />
          <Typography variant='captionSmall' sx={{ pl: 1 }}>
            Missing
          </Typography>
        </ToggleButton>
      )
    case FilterType.not_null:
      return (
        <ToggleButton key={filterType} value={filterType}>
          <CheckIcon />
          <Typography variant='captionSmall' sx={{ pl: 1 }}>
            Exists
          </Typography>
        </ToggleButton>
      )
    default:
      return null
  }
}

export default FilterAttributeCollectionWidget
