import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { FormControl, IconButton, InputLabel, useTheme } from '@mui/material'
import { Box, Grid, Tooltip } from '@northvolt/ui'
import type {
  AdhocFilter,
  AttributeCollectionLight,
  MaterialType,
} from 'client/model'
import type { SelectableAttributeCollection } from '../DataLoader/DataLoaderTypes'
import FilterAttributeCollectionWidget from './FilterAttributeCollectionWidget'

interface FilterAttributeCollectionBoxProps {
  attributeCollections:
    | SelectableAttributeCollection[]
    | AttributeCollectionLight[]
    | undefined
  filters: AdhocFilter[]
  setFilters: (filters: AdhocFilter[]) => void
  disabled?: boolean
  grain?: MaterialType
}

const FilterAttributeCollectionBox: React.FC<
  FilterAttributeCollectionBoxProps
> = ({ attributeCollections, filters, setFilters, grain }) => {
  const theme = useTheme()
  const mode = attributeCollections === undefined ? 'search' : 'select'
  return (
    <FormControl fullWidth sx={{ height: '100%' }}>
      <InputLabel
        shrink
        id='filters-label'
        style={{ backgroundColor: 'white' }}>
        {' '}
        Filters{' '}
      </InputLabel>
      <Grid
        container
        columns={12}
        border={0.5}
        borderRadius={1}
        borderColor={theme.palette.grey[700]}>
        {filters.map((filter, index) => (
          <Grid key={index} xs={12} lg={6} xl={4}>
            <FilterAttributeCollectionWidget
              key={index}
              filter={filter}
              setFilter={filter => {
                if (!filter) {
                  const newFilters = [...filters]
                  newFilters.splice(index, 1)
                  setFilters(newFilters)
                  return
                }
                const newFilters = [...filters]
                newFilters[index] = filter
                setFilters(newFilters)
              }}
              attributeCollections={attributeCollections}
              grain={grain}
            />
          </Grid>
        ))}
        <Grid xs={12} lg={6} xl={4}>
          <Tooltip
            title={
              attributeCollections?.length || mode === 'search'
                ? 'Add Filter'
                : 'Select Dataset First'
            }
            placement='top'>
            <Box
              border={1}
              borderRadius={1}
              borderColor={theme.palette.grey[600]}
              sx={{ m: 1, p: 1, display: 'inline-block' }}>
              <IconButton
                onClick={() =>
                  setFilters([
                    ...filters,
                    {
                      attribute: null as any,
                      attribute_id: null as any,
                      type: null as any,
                    },
                  ])
                }
                disabled={!(attributeCollections?.length || mode === 'search')}>
                <AddCircleOutlineIcon sx={{ color: 'secondary.light' }} />
              </IconButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </FormControl>
  )
}

export default FilterAttributeCollectionBox
