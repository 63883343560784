import { Chip } from '@mui/material'
import { Autocomplete, Checkbox, Stack, TextField } from '@northvolt/ui'
import type {
  AdhocFilter,
  AttributeStatistics,
  StringValue,
} from 'client/model'
import { DataType } from 'components/Utils'
import { useEffect } from 'react'

export interface EqualityFilterControlProps {
  attributeStatistics: AttributeStatistics
  filter: AdhocFilter
  setFilter: (adhocFilter: AdhocFilter) => void
}

const EqualityFilterControl: React.FC<EqualityFilterControlProps> = ({
  attributeStatistics,
  filter,
  setFilter,
}) => {
  const options = attributeStatistics.string_distinct_values
    ? attributeStatistics.string_distinct_values.sort()
    : []
  const isFreeSolo =
    !attributeStatistics.string_distinct_values ||
    attributeStatistics.string_distinct_values.length === 5000
  const getValue = () => {
    if (filter.multiple) {
      return filter.multiple_values
        ? filter.multiple_values.map(el => el.value)
        : []
    }
    if (filter.value) {
      return filter.value
    }
    return []
  }
  const setValue = (newValue: any) => {
    if (filter.multiple) {
      const stringValues: StringValue[] = newValue.map((value: string) => ({
        value: value,
      }))
      setFilter({ ...filter, multiple_values: stringValues, value: undefined })
    } else {
      setFilter({ ...filter, value: newValue, multiple_values: undefined })
    }
  }

  const handlePaste = (event: React.ClipboardEvent) => {
    if (filter.multiple) {
      event.preventDefault()
      const pastedText = event.clipboardData.getData('text')
      const newItems = pastedText.split(',').map((item: string) => item.trim())
      const stringValues: StringValue[] = newItems.map((value: string) => ({
        value: value,
      }))

      setFilter({ ...filter, multiple_values: stringValues, value: undefined })
    }
  }

  useEffect(() => {
    if (
      filter.attribute.data_type === DataType.STRING ||
      filter.attribute.data_type === DataType.TIMESTAMP ||
      filter.attribute.data_type === DataType.DATE
    ) {
      setFilter({ ...filter, needs_quoting: true })
    } else {
      setFilter({ ...filter, needs_quoting: false })
    }
  }, [filter.attribute?.data_type])

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <Autocomplete
        size='small'
        fullWidth
        freeSolo={isFreeSolo}
        options={options}
        value={getValue()}
        onChange={(_: any, newValue: any) => setValue(newValue)}
        onPaste={handlePaste}
        renderInput={params => (
          <TextField {...params} label='Select Values' variant='outlined' />
        )}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const props = getTagProps({ index })
            const { key, ...rest } = props
            return (
              <Chip
                key={index}
                variant='outlined'
                size='small'
                label={option}
                {...rest}
              />
            )
          })
        }
        multiple={!!filter.multiple}
        autoHighlight
        autoComplete
        disableClearable
        disableCloseOnSelect={filter.multiple}
      />
      <Checkbox
        title='Multiple'
        label='Multiple'
        checked={!!filter.multiple}
        value={!!filter.multiple}
        onChange={({ target }) => {
          setFilter({
            ...filter,
            multiple: target.checked,
            value: undefined,
            multiple_values: undefined,
          })
        }}
      />
    </Stack>
  )
}

export default EqualityFilterControl
