import type { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro'
import { DateRangePicker, StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import type { AdhocFilter, AttributeStatistics } from 'client/model'
import React, { useEffect } from 'react'

import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import { reformatDateString } from '../Utils'

export interface DatetimeRangeFilterControlProps {
  attributeStatistics: AttributeStatistics
  filter: AdhocFilter
  setFilter: (adhocFilter: AdhocFilter) => void
  staticMode?: boolean
  disabled?: boolean
}

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(7, 'day'), today]
    },
  },
  {
    label: 'Last 14 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(14, 'day'), today]
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(30, 'day'), today]
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
]

const DatetimeRangeFilterControl: React.FC<DatetimeRangeFilterControlProps> = ({
  filter,
  setFilter,
  staticMode,
  disabled,
}) => {
  const getInitialDateRange = () => {
    const startDate = filter?.min_datetime
      ? dayjs(reformatDateString(filter.min_datetime))
      : dayjs().subtract(7, 'day')
    const endDate = filter?.max_datetime
      ? dayjs(reformatDateString(filter.max_datetime))
      : dayjs()
    return [startDate, endDate] as DateRange<Dayjs>
  }

  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>(
    getInitialDateRange(),
  )

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setFilter({
        ...filter,
        min_datetime: dateRange[0].toISOString(),
        max_datetime: dateRange[1].toISOString(),
      })
    }
  }, [dateRange])

  useEffect(() => {
    if (
      filter !== undefined &&
      filter.min_datetime === undefined &&
      filter.max_datetime === undefined
    ) {
      setDateRange([dayjs().subtract(30, 'day'), dayjs()])
    }
  }, [filter])

  if (staticMode) {
    return (
      <StaticDateRangePicker
        value={dateRange}
        onChange={setDateRange}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        disableFuture
        disabled={disabled}
      />
    )
  }
  return (
    <DateRangePicker
      value={dateRange}
      onChange={setDateRange}
      slotProps={{
        shortcuts: {
          items: shortcutsItems,
        },
        actionBar: { actions: [] },
        textField: {
          size: 'small',
        },
      }}
      disableFuture
      disabled={disabled}
    />
  )
}

export default DatetimeRangeFilterControl
