/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */

/**
 * An enumeration.
 */
export type CellKind = typeof CellKind[keyof typeof CellKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CellKind = {
  PPE_CV: 'PPE CV',
  PPE_SLIM: 'PPE SLIM',
  PPE_HIGH: 'PPE HIGH',
  EV32: 'EV32',
  GPA: 'GPA',
} as const;
