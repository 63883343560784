import { CircularProgress, Grid, NVThemeProvider } from '@northvolt/ui'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'

import {
  ForceRefresh,
  SnowflakeProvider,
  configureSnowflake,
  getIdToken,
} from '@northvolt/snowflake'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'
import LoginForm from 'components/LoginForm'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import ErrorRoute from 'routes/404'
import HelpRoute from 'routes/Help'
import LandingRoute from 'routes/Landing'
import RootRoute from 'routes/Root'

import DatasetEditRouter from 'routes/Atlas/datasets/edit'
import DatasetIdRouter from 'routes/Atlas/datasets/id'
import DatasetNewRouter from 'routes/Atlas/datasets/new'

import MyDataRouter from 'routes/Atlas/my-data'
import SearchRoute from 'routes/Atlas/search'

import AttributesRoute from 'routes/Atlas/attributes'

import SampleEditRouter from 'routes/Atlas/samples/edit'
import SampleIdRouter from 'routes/Atlas/samples/id'
import SampleNewRouter from 'routes/Atlas/samples/new'

import AttributeCollectionEditRouter from 'routes/Atlas/attribute-collections/edit'
import AttributeCollectionIdRouter from 'routes/Atlas/attribute-collections/id'
import AttributeCollectionNewRouter from 'routes/Atlas/attribute-collections/new'

import ToolsChangeDetectorRoute from 'tools/changeDetector'
import ToolsCorrelationFinderRoute from 'tools/correlationFinder'
import ToolsCorrelationFinderResultRoute from 'tools/correlationFinder/CorrelationFinderResult'
import ToolsPlotterRoute from 'tools/plotter'
import ToolsTurboFishbonesRoute from 'tools/turboFishbones'

import SpcProcessCapability from 'routes/Spc/process-capability'

function QueryProvider({ children }: { children: React.ReactNode }) {
  const [currentToken, setCurrentToken] = useState(getIdToken())
  axios.defaults.baseURL = import.meta.env.VITE_API_URI
  axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        //@ts-expect-error async works
        retry: async (failureCount, error) => {
          if (error) {
            await ForceRefresh()
            const idToken = getIdToken()
            if (!idToken) {
              window.location.reload()
            }
            setCurrentToken(idToken)
            return 1
          }
          return failureCount < 2
        },
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootRoute />,
    errorElement: <ErrorRoute />,
    children: [
      { path: '', element: <LandingRoute /> },
      { path: '/help', element: <HelpRoute /> },

      { path: '/atlas/search', element: <SearchRoute /> },

      { path: '/atlas/datasets/new', element: <DatasetNewRouter /> },
      { path: '/atlas/datasets/:datasetId', element: <DatasetIdRouter /> },
      {
        path: '/atlas/datasets/:datasetId/edit',
        element: <DatasetEditRouter />,
      },

      { path: '/atlas/my-data', element: <MyDataRouter /> },
      {
        path: 'atlas/attributes/:attributeId',
        element: <AttributesRoute />,
      },

      { path: '/atlas/samples/new', element: <SampleNewRouter /> },
      { path: '/atlas/samples/:sampleId', element: <SampleIdRouter /> },
      { path: '/atlas/samples/:sampleId/edit', element: <SampleEditRouter /> },

      {
        path: '/atlas/attribute-collections/new',
        element: <AttributeCollectionNewRouter />,
      },
      {
        path: '/atlas/attribute-collections/:attributeId',
        element: <AttributeCollectionIdRouter />,
      },
      {
        path: '/atlas/attribute-collections/:attributeId/edit',
        element: <AttributeCollectionEditRouter />,
      },

      { path: '/tools/plotter', element: <ToolsPlotterRoute /> },
      { path: '/tools/change-detector', element: <ToolsChangeDetectorRoute /> },
      {
        path: '/tools/correlation-finder',
        element: <ToolsCorrelationFinderRoute />,
      },
      {
        path: '/tools/correlation-finder/result/:id?',
        element: <ToolsCorrelationFinderResultRoute />,
      },
      { path: '/tools/turbo-fishbones', element: <ToolsTurboFishbonesRoute /> },

      { path: '/spc/process-capability', element: <SpcProcessCapability /> },
    ],
  },
])

function App() {
  useEffect(() => {
    configureSnowflake({
      AUTH_SCOPE: 'rome',
      AUTH_LOGIN_URL: import.meta.env.VITE_AUTH_LOGIN_URL,
      COGNITO_CLIENT_ID: import.meta.env.VITE_COGNITO_CLIENT_ID,
      COGNITO_USER_POOL_URL: import.meta.env.VITE_COGNITO_USER_POOL_URL,
      COGNITO_IDENTITY_PROVIDER: 'Northvolt',
    })

    mixpanel.init('a6690870c437905dea8db4ac58688673', {
      api_host: 'https://api-eu.mixpanel.com',
      persistence: 'localStorage',
      track_pageview: true,
      debug: false,
    })
  }, [])

  return (
    <NVThemeProvider>
      <SnowflakeProvider
        loadingState={
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{ minHeight: '100vh' }}>
            <CircularProgress
              sx={{
                alignSelf: 'center',
                marginBottom: 3,
                textAlign: 'center',
                color: '#44a27a',
              }}
              size={64}
            />
          </Grid>
        }
        signedOutState={<LoginForm />}>
        <QueryProvider>
          <RouterProvider router={router} />
        </QueryProvider>
      </SnowflakeProvider>
    </NVThemeProvider>
  )
}

export default App
