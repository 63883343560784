import type React from 'react'
import { useState } from 'react'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BookIcon from '@mui/icons-material/Book'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import HomeIcon from '@mui/icons-material/Home'
import InsightsIcon from '@mui/icons-material/Insights'
import SearchIcon from '@mui/icons-material/Search'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AppMenu, Box, type TAppMenuListItem } from '@northvolt/ui'
import { NVLocalizationProviderDayjs } from '@northvolt/ui/ProviderDayjs'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import VeronaTopBar from './VeronaTopBar'

interface VeronaAppMenuProps {
  children: React.ReactNode
}

const VeronaAppMenu: React.FC<VeronaAppMenuProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const menuListItems: (TAppMenuListItem & { startPath?: string })[] = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      onClick: () => navigate('/'),
      active: !!matchPath(pathname, '/'),
    },
    { label: 'Data Atlas', type: 'heading' },
    {
      label: 'Search',
      icon: <SearchIcon />,
      onClick: () => navigate('/atlas/search'),
      active: !!matchPath(pathname, '/atlas/search'),
    },
    {
      label: 'My Data',
      icon: <BookIcon />,
      onClick: () => navigate('/atlas/my-data'),
      active: !!matchPath(pathname, '/atlas/my-data'),
    },
    { label: 'Data Exploration Tools', type: 'heading' },
    {
      label: 'Plotter',
      icon: <InsightsIcon />,
      onClick: () => navigate('/tools/plotter'),
      active: !!matchPath(pathname, '/tools/plotter'),
    },
    {
      label: 'Change Detector',
      icon: <TrackChangesIcon />,
      onClick: () => navigate('/tools/change-detector'),
      active: !!matchPath(pathname, '/tools/change-detector'),
    },
    {
      label: 'Correlation Finder',
      icon: <TroubleshootIcon />,
      onClick: () => navigate('/tools/correlation-finder'),
      active: !!matchPath(pathname, '/tools/correlation-finder'),
    },
    {
      label: 'Turbo Fishbones',
      icon: <AccountTreeIcon />,
      onClick: () => navigate('/tools/turbo-fishbones'),
      active: !!matchPath(pathname, '/tools/turbo-fishbones'),
    },
    { label: 'Statistical Process Control', type: 'heading' },
    {
      label: 'Process Capability',
      icon: <EqualizerIcon />,
      onClick: () => navigate('/spc/process-capability'),
      active: !!matchPath(pathname, '/spc/process-capability'),
    },
  ]

  return (
    <NVLocalizationProviderDayjs dateAdapter={AdapterDayjs}>
      <Box display='flex' flexDirection={'column'} height={'100vh'}>
        <AppMenu
          open={open}
          onOpenChange={setOpen}
          menuListItems={menuListItems}
          offsetAppBar={false}>
          <VeronaTopBar menuOpen={open} setMenuOpen={setOpen} />
          <Box
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
            {children}
          </Box>
        </AppMenu>
      </Box>
    </NVLocalizationProviderDayjs>
  )
}

export default VeronaAppMenu
