import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { Switch } from '@northvolt/ui'
import { type Reducer, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  type AdhocFilter,
  type Attribute,
  type AttributeCollection,
  type AttributeCollectionLight,
  type AttributeLight,
  type DataLoadingParamsLight,
  MaterialType,
  type Sample,
  type SampleLight,
} from 'client/model'
import {
  useRunCorrelationFinder,
  useSearchAttributeCollectionsByText,
  useSearchAttributesByText,
  useSearchSamplesByText,
} from 'client/wattson-client'
import AttributeCollectionSearchAndSelect from 'components/AttributeCollection/AttributeCollectionSearchAndSelect'

import AttributeSearchAndSelect from 'components/Attribute/AttributeSearchAndSelect'
import StratificationControl from 'components/DataLoader/StratificationControl'
import FilterAttributeCollectionBox from 'components/Filter/FilterAttributeCollectionBox'
import SampleSearchAndSelect from 'components/Sample/SampleSearchAndSelect'
import { type CorrelationState, CorrelationStateEnum } from './Types'

import { allowedGrains, isDateOrTime } from 'components/Utils'
import styles from './correlation-finder.module.scss'

const limitValues = [1000, 5000, 10000, 25000, 50000, 100000, 250000]

const keyMap = {
  blocklist: 'attribute_blocklist',
  attributeCollections: 'attribute_collection_ids',
  samples: 'sample_ids',
} as const

const setBlockSubmit = (state: CorrelationState) => {
  if (state.sending) return true
  if (state.sample_mode) {
    if (
      state.data_loading_params.sample_ids.length < 2 ||
      !state.data_loading_params.attribute_collection_ids.length
    ) {
      return true
    }
    return false
  }
  if (
    state.data_loading_params.filters?.some(
      ({ type }: AdhocFilter) => !type || type === null,
    )
  ) {
    return true
  }
  if (
    !state.target_attribute.id ||
    !state.data_loading_params.attribute_collection_ids.length ||
    !state.data_loading_params.sample_ids.length
  ) {
    return true
  }
  return false
}

type Action = {
  type: CorrelationStateEnum
  payload: any
}

const correlationReducer = (
  state: CorrelationState,
  action: Action,
): CorrelationState => {
  const { type, payload } = action
  if (payload === undefined) return state
  switch (type) {
    case CorrelationStateEnum.TARGET_ATTRIBUTE: {
      const newState = {
        ...state,
        target_attribute: {
          column_description: `${payload?.column_description ?? ''}`,
          column_name: payload.column_name,
          data_type: payload.data_type,
          id: payload.id,
          grain: payload.grain,
          unique_name: payload.unique_name,
        },
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.DATA_LOADING_PARAMS: {
      const newState = {
        ...state,
        data_loading_params: {
          ...state.data_loading_params,
          ...payload,
        },
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.FILTERS: {
      const newState = {
        ...state,
        data_loading_params: {
          ...state.data_loading_params,
          filters: payload,
        },
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.SAMPLES:
    case CorrelationStateEnum.BLOCKLIST:
    case CorrelationStateEnum.ATTRIBUTECOLLECTIONS: {
      const newState = {
        ...state,
        data_loading_params: {
          ...state.data_loading_params,
          [keyMap[type]]: (
            payload as
              | AttributeCollectionLight[]
              | SampleLight[]
              | AttributeLight[]
          ).map(({ id }: { id: number }) => id),
        },
        [type]: payload as
          | AttributeCollectionLight[]
          | SampleLight[]
          | AttributeLight[],
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.SAMPLE_MODE: {
      const newState = {
        ...state,
        sample_mode: payload,
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    default: {
      return {
        ...state,
        blockSubmit: setBlockSubmit(state),
        [type]: payload,
      }
    }
  }
}

export default ({
  init,
}: {
  init?: {
    data_loading_params?: DataLoadingParamsLight
    target_attribute?: Attribute
    sample_mode?: boolean
  }
}) => {
  const [payload, dispatch] = useReducer<Reducer<CorrelationState, Action>>(
    correlationReducer,
    {
      data_loading_params: {
        filters: init?.data_loading_params?.filters || ([] as AdhocFilter[]),
        grain: init?.data_loading_params?.grain || MaterialType.Prismatic_Cell,
        row_limit: init?.data_loading_params?.row_limit || 1000,
        randomize: !!init?.data_loading_params?.randomize,
        attribute_collection_ids:
          init?.data_loading_params?.attribute_collections.map(
            ({ id }) => id,
          ) || ([] as number[]),
        attribute_blocklist:
          init?.data_loading_params?.attribute_blocklist.map(({ id }) => id) ||
          ([] as number[]),
        sample_ids:
          init?.data_loading_params?.samples.map(({ id }) => id) ||
          ([] as number[]),
        stratification_col_unique_name:
          init?.data_loading_params?.stratification_col_unique_name || '',
      },
      target_attribute: {
        column_description: init?.target_attribute?.column_description || '',
        column_name: init?.target_attribute?.column_name || '',
        data_type: init?.target_attribute?.data_type || '',
        id: init?.target_attribute?.id || 0,
        grain: init?.target_attribute?.grain || MaterialType.Prismatic_Cell,
        unique_name: init?.target_attribute?.unique_name || '',
      },
      attributeCollections:
        init?.data_loading_params?.attribute_collections ||
        ([] as AttributeCollection[]),
      samples: init?.data_loading_params?.samples || ([] as Sample[]),
      blocklist:
        init?.data_loading_params?.attribute_blocklist || ([] as Attribute[]),
      suggestedAttributeCollections: [] as AttributeCollection[],
      suggestedSamples: [] as Sample[],
      suggestedTargets: [] as Attribute[],
      suggestedBlocklist: [] as Attribute[],
      blockSubmit:
        (!init?.sample_mode && !init?.target_attribute?.id) ||
        !init?.data_loading_params?.attribute_collections?.length ||
        !init?.data_loading_params.samples?.length,
      sample_mode: !!init?.sample_mode,
      errors: [] as string[],
      sending: false,
    } as CorrelationState,
  )

  const attributeCollectionsLoader = useSearchAttributeCollectionsByText()
  const attributeLoader = useSearchAttributesByText()
  const correlationLoader = useRunCorrelationFinder()
  const sampleLoader = useSearchSamplesByText()
  const navigate = useNavigate()

  const canFilter =
    payload.target_attribute?.id &&
    payload.attributeCollections?.length &&
    payload.samples?.length

  useEffect(() => {
    attributeCollectionsLoader.mutate({
      params: {
        text: 'all',
        only_user_collections: false,
        grain: payload.data_loading_params.grain,
      },
    })
    sampleLoader.mutate({
      params: {
        text: 'a',
        grain: payload.data_loading_params.grain,
      },
    })
    attributeLoader.mutate({
      params: {
        text: 'a',
        grain: payload.data_loading_params.grain,
      },
    })
  }, [])

  const handleSubmit = () => {
    dispatch({ type: CorrelationStateEnum.SENDING, payload: true })
    const { data_loading_params, target_attribute, sample_mode } = payload
    const data = sample_mode
      ? {
          data: { data_loading_params },
          params: { sample_mode },
        }
      : {
          data: { data_loading_params, target_attribute },
        }
    correlationLoader.mutate(data)
  }

  useEffect(() => {
    const { isSuccess, isError, data, error } = correlationLoader
    if (isError) {
      dispatch({
        type: CorrelationStateEnum.ERRORS,
        payload: [error.response?.data.detail],
      })
      return
    }

    if (!isSuccess) return
    const pageParams = {
      targetName: payload.target_attribute?.unique_name,
      sampleNames: payload.samples.map(({ name }) => name),
      attributeCollectionNames: payload.attributeCollections.map(
        ({ name }) => name,
      ),
    }
    const current = localStorage.getItem('__correlation-finder')
    const state = current ? JSON.parse(current) : {}
    localStorage.setItem(
      '__correlation-finder',
      JSON.stringify({ ...state, [data.data.id]: pageParams }),
    )
    dispatch({ type: CorrelationStateEnum.SENDING, payload: false })
    navigate(
      `/tools/correlation-finder/result/${correlationLoader.data.data.id}`,
    )
  }, [correlationLoader])

  useEffect(() => {
    if (attributeCollectionsLoader.data) {
      const items = attributeCollectionsLoader.data?.data.items
      const suggestedAttributeCollections = items.slice(0, 5)
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDATTRIBUTECOLLECTIONS,
        payload: suggestedAttributeCollections,
      })
    }
  }, [attributeCollectionsLoader.data])

  useEffect(() => {
    if (sampleLoader.data) {
      const items = sampleLoader.data?.data.items
      const suggestedSamples = items.slice(0, 5)
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDSAMPLES,
        payload: suggestedSamples,
      })
    }
  }, [sampleLoader.data])

  useEffect(() => {
    if (attributeLoader.data) {
      const items = attributeLoader.data?.data.items
      const suggestedTargets = items.slice(6)
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDTARGETS,
        payload: suggestedTargets,
      })
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDBLOCKLIST,
        payload: suggestedTargets,
      })
    }
  }, [attributeLoader.data])

  const filterOptions = (attr: Attribute) =>
    !!attr &&
    !isDateOrTime(attr) &&
    !payload.blocklist.includes(attr) &&
    !attr.column_name.includes('cell_id') &&
    !attr.column_name.includes('pr_id')

  return (
    <div className={styles.formGroup}>
      <div className={styles.formContainer}>
        <div className={styles.sample_mode}>
          <Switch
            label='Sample mode'
            checked={payload.sample_mode}
            onChange={() =>
              dispatch({
                type: CorrelationStateEnum.SAMPLE_MODE,
                payload: !payload.sample_mode,
              })
            }
          />
        </div>

        <div className={styles.grain}>
          <FormControl fullWidth>
            <InputLabel id='grain-label'>Grain</InputLabel>
            <Select
              id='grain'
              label='Grain'
              labelId='grain-label'
              size='small'
              value={payload.data_loading_params.grain}
              onChange={({ target }) => {
                dispatch({
                  type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                  payload: { grain: target.value },
                })
              }}
              fullWidth
              required>
              {Object.values(MaterialType).map(materialType => (
                <MenuItem
                  key={materialType}
                  value={materialType}
                  disabled={!allowedGrains.includes(materialType)}>
                  {materialType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {!payload.sample_mode && (
          <AttributeSearchAndSelect
            label='Correlation target'
            placeholder='Select a target attribute'
            initialSuggestions={payload.suggestedTargets}
            selected={init?.target_attribute ? [init.target_attribute] : []}
            setSelected={(target: Attribute[]) =>
              dispatch({
                type: CorrelationStateEnum.TARGET_ATTRIBUTE,
                payload: target?.[0],
              })
            }
            grain={payload.data_loading_params.grain}
            multiple={false}
            {...filterOptions}
          />
        )}
      </div>
      <div className={styles.formContainer}>
        <AttributeCollectionSearchAndSelect
          label='Attribute Collection'
          placeholder='Select attribute collections'
          suggestedAttributeCollections={[
            ...payload.suggestedAttributeCollections,
          ]}
          selectedAttributeCollections={payload.attributeCollections}
          setSelectedAttributeCollections={attributeCollections =>
            dispatch({
              type: CorrelationStateEnum.ATTRIBUTECOLLECTIONS,
              payload: attributeCollections,
            })
          }
          grain={payload.data_loading_params.grain}
          multiple={true}
        />

        <SampleSearchAndSelect
          label='Samples'
          placeholder={
            payload.sample_mode
              ? 'Select at least two samples'
              : 'Select samples'
          }
          suggestedSamples={[...payload.suggestedSamples]}
          selectedSamples={payload.samples}
          setSelectedSamples={samples =>
            dispatch({ type: CorrelationStateEnum.SAMPLES, payload: samples })
          }
          grain={payload.data_loading_params.grain}
          multiple={true}
        />
      </div>

      <div className={styles.formContainerReverse}>
        <AttributeSearchAndSelect
          label='Blocklist'
          placeholder='Select attributes to ignore in the calculations'
          initialSuggestions={payload.suggestedBlocklist}
          selected={payload.blocklist}
          setSelected={blocklist => {
            if (Array.isArray(blocklist))
              dispatch({
                type: CorrelationStateEnum.BLOCKLIST,
                payload: blocklist,
              })
          }}
          grain={payload.data_loading_params.grain}
          multiple={true}
          filterOptions={attr =>
            !!attr && attr.id !== payload.target_attribute?.id
          }
        />

        <div className={styles.rowLimit}>
          <FormControl>
            <InputLabel id='row-limit-label'>Row Limit </InputLabel>
            <Select
              id='row-limit'
              label='Row Limit'
              labelId='row-limit-label'
              size='small'
              value={payload.data_loading_params.row_limit}
              onChange={({ target }) =>
                dispatch({
                  type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                  payload: { row_limit: target.value },
                })
              }
              sx={{ width: '100%' }}>
              {limitValues.map(v => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.random}>
          <Switch
            label='Randomize'
            checked={payload.data_loading_params.randomize}
            onChange={() =>
              dispatch({
                type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                payload: { randomize: !payload.data_loading_params.randomize },
              })
            }
          />
        </div>
      </div>

      <div className={styles.formContainerReverse}>
        <div className={styles.stratify}>
          <StratificationControl
            attributeCollections={[...payload.attributeCollections]}
            stratificationAttributeName={
              payload.data_loading_params.stratification_col_unique_name
            }
            setStratificationAttributeName={attributeName =>
              dispatch({
                type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                payload: { stratification_col_unique_name: attributeName },
              })
            }
          />
        </div>
      </div>

      <FilterAttributeCollectionBox
        attributeCollections={
          payload?.attributeCollections ? payload.attributeCollections : []
        }
        filters={
          payload.data_loading_params?.filters
            ? payload.data_loading_params.filters
            : []
        }
        setFilters={filters => {
          dispatch({
            type: CorrelationStateEnum.FILTERS,
            payload: filters,
          })
        }}
        disabled={!canFilter}
      />

      <Button
        fullWidth
        variant='contained'
        size='medium'
        disabled={payload.blockSubmit}
        onClick={handleSubmit}>
        Run Correlation Finder
      </Button>
      {!!payload.errors?.length &&
        payload.errors?.map(error => (
          <p key={error} className={styles.error}>
            {error}
          </p>
        ))}
    </div>
  )
}
