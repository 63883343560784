import { Box, useTheme } from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import type { SelectableAttribute } from 'components/DataLoader/DataLoaderTypes'
import {
  AttributeType,
  getAttributeType,
  getTimeInterval,
} from 'components/Utils'
import { useEffect, useRef, useState } from 'react'
import { colorScheme, colorSchemeCat } from './PlotUtils'

interface Plot1DProps {
  attribute: SelectableAttribute
  width: number
  height: number
  isColorAttr?: boolean
  isSizeAttr?: boolean
  $filterParam: any
  renderId?: number
}

const Plot1D: React.FC<Plot1DProps> = ({
  attribute,
  width,
  height,
  isColorAttr,
  $filterParam,
  renderId,
}) => {
  const [plot, setPlot] = useState<any>(null)

  const theme = useTheme()
  const themeGrey = theme.palette.grey[700]

  const plotRef = useRef<any>(null)

  useEffect(() => {
    if (attribute && width && height < Number.POSITIVE_INFINITY) {
      const updatePlot = async () => {
        const attrType = getAttributeType(attribute)
        if (attrType === AttributeType.numeric) {
          const p = await getNumericalPlot(
            attribute,
            $filterParam,
            width,
            height,
            isColorAttr || false,
            themeGrey,
          )
          setPlot(p)
        } else if (attrType === AttributeType.datetime) {
          const p = await getTimestampPlot(
            attribute,
            $filterParam,
            width,
            height,
            isColorAttr || false,
            themeGrey,
          )
          setPlot(p)
        } else if (attrType === AttributeType.categorical) {
          const p = getCategoricalPlot(
            attribute,
            $filterParam,
            width,
            height,
            isColorAttr || false,
            themeGrey,
          )
          setPlot(p)
        } else {
          console.log('Unknown attribute type')
        }
      }
      updatePlot()
    } else {
      setPlot(vg.vspace(0))
    }
  }, [attribute, width, height, isColorAttr, themeGrey, renderId])

  useEffect(() => {
    if (plotRef.current && plot) {
      plotRef.current.appendChild(plot)
      return () => {
        if (plotRef.current) {
          plotRef.current.removeChild(plot)
        }
      }
    }
  }, [plot, plotRef])

  return <Box ref={plotRef} />
}

export default Plot1D

function getNumericalPlot(
  attribute: SelectableAttribute,
  $filterParam: any,
  width: number,
  height: number,
  isColorAttr: boolean,
  themeGrey: string,
) {
  const fillColor = isColorAttr
    ? vg.median(vg.column(attribute?.unique_name))
    : themeGrey
  const q = `SELECT COUNT(DISTINCT "${attribute?.unique_name}") AS dist_count, MEDIAN("${attribute?.unique_name}") as med FROM active_table;`
  return vg
    .coordinator()
    .query(q, { type: 'json' })
    .then((result: any) => {
      const { dist_count, med } = JSON.parse(result.toString())
      return { dist_count, med }
    })
    .then(({ dist_count }) => {
      const x =
        dist_count > 2
          ? vg.bin(attribute?.unique_name)
          : vg.column(attribute?.unique_name)
      const plot = vg.plot(
        vg.rectY(
          // Background greyed out histogram
          vg.from('active_table'),
          {
            x: x,
            y: vg.count(),
            z: null,
            stroke: 'black',
            fill: fillColor,
            inset: 0.5,
            opacity: 0.2,
          },
        ),
        vg.rectY(vg.from('active_table', { filterBy: $filterParam }), {
          x: x,
          y: vg.count(),
          stroke: 'black',
          fill: fillColor,
          inset: 0.5,
          tip: true,
        }),
        dist_count > 2
          ? vg.intervalX({ as: $filterParam })
          : vg.toggleX({ as: $filterParam }),
        vg.colorDomain(vg.Fixed),
        vg.width(width),
        vg.height(height),
        vg.marginBottom(40),
        vg.colorScheme(colorScheme),
        vg.colorScale('log'),
        vg.xLabel(`${attribute.column_name} →`),
        vg.colorLabel(isColorAttr ? attribute.column_name : null),
      )
      return plot
    })
}

function getTimestampPlot(
  attribute: SelectableAttribute,
  $filterParam: any,
  width: number,
  height: number,
  isColorAttr: boolean,
  themeGrey: string,
) {
  //TODO: this whole broken timezone thing is a mess (DuckDB wasm doesn't support loading the icu module)
  const timeCol = vg.column(attribute?.unique_name)
  const limitQuery = `
    SELECT
      MIN(${timeCol}) AS min,
      MAX(${timeCol}) AS max,
      date_diff('day', MIN(CAST(${timeCol} AS TIMESTAMP)), MAX(CAST(${timeCol} AS TIMESTAMP))) as days_apart
    FROM active_table;`

  return vg
    .coordinator()
    .query(limitQuery, { type: 'json' })
    .then((bounds: any) => {
      const { min, max, days_apart } = JSON.parse(bounds.toString())
      const timeInterval = getTimeInterval(days_apart)
      vg.coordinator().exec(
        `CREATE TEMP TABLE IF NOT EXISTS data_on_spine AS
          WITH spine AS (
            SELECT
              generate_series AS ${timeCol}
            FROM generate_series(
              CAST(to_timestamp(${min} / 1000.0) AS TIMESTAMP),
              CAST(to_timestamp(${max} / 1000.0) AS TIMESTAMP),
              interval '1 ${timeInterval}')
          ),
          actual_data AS (
            SELECT
              *,
              1 AS _dummy_count_
            FROM active_table
          )
          SELECT * FROM actual_data FULL OUTER JOIN spine USING (${timeCol});`,
      )
      return timeInterval
    })
    .then((timeInterval: string) => {
      const color = isColorAttr
        ? vg.median(vg.column(attribute?.unique_name))
        : 'black'
      const chart = vg.plot(
        vg.dot(vg.from('data_on_spine'), {
          x: vg.sql`date_trunc('${timeInterval}', CAST(${vg.column(attribute?.unique_name)} AS TIMESTAMP))`,
          y: vg.sum('_dummy_count_'),
          stroke: themeGrey,
          fill: themeGrey,
        }),
        vg.ruleX(vg.from('data_on_spine'), {
          x: vg.sql`date_trunc('${timeInterval}', CAST(${vg.column(attribute?.unique_name)} AS TIMESTAMP))`,
          y: vg.sum('_dummy_count_'),
          stroke: themeGrey,
        }),
        vg.dot(vg.from('data_on_spine', { filterBy: $filterParam }), {
          x: vg.sql`date_trunc('${timeInterval}', CAST(${vg.column(attribute?.unique_name)} AS TIMESTAMP))`,
          y: vg.sum('_dummy_count_'),
          stroke: color,
          fill: color,
          z: null,
          tip: true,
        }),
        vg.ruleX(vg.from('data_on_spine', { filterBy: $filterParam }), {
          x: vg.sql`date_trunc('${timeInterval}', CAST(${vg.column(attribute?.unique_name)} AS TIMESTAMP))`,
          y: vg.sum('_dummy_count_'),
          stroke: color,
        }),
        vg.marginBottom(40),
        vg.xLabel(`${attribute.column_name} →`),
        vg.yLabel('↑ count'),
        vg.colorLabel(attribute?.column_name),
        vg.intervalX({ as: $filterParam }),
        vg.width(width),
        vg.height(height),
        vg.xDomain(vg.Fixed),
        vg.yDomain(vg.Fixed),
        vg.colorDomain(vg.Fixed),
        vg.colorScheme(colorScheme),
        vg.colorScale('log'),
      )
      return chart
    })
}

function getCategoricalPlot(
  attribute: SelectableAttribute,
  $filterParam: any,
  width: number,
  height: number,
  isColorAttr: boolean,
  themeGrey: string,
) {
  const fillColor = isColorAttr
    ? vg.median(vg.column(attribute?.unique_name))
    : themeGrey
  return vg.plot(
    vg.rectY(
      // Background greyed out histogram
      vg.from('active_table'),
      {
        x: attribute?.unique_name,
        y: vg.count(),
        stroke: 'black',
        fill: fillColor,
        inset: 0.5,
        opacity: 0.2,
      },
    ),
    vg.rectY(vg.from('active_table', { filterBy: $filterParam }), {
      x: attribute?.unique_name,
      y: vg.count(),
      stroke: 'black',
      fill: fillColor,
      inset: 0.5,
      tip: true,
    }),
    vg.marginBottom(40),
    vg.toggleX({ as: $filterParam }),
    vg.width(width),
    vg.height(height),
    vg.colorScheme(colorSchemeCat),
    vg.colorDomain(vg.Fixed),
    vg.xLabel(`${attribute.column_name} →`),
    vg.xTickRotate(45),
    vg.colorLabel(isColorAttr ? attribute?.column_name : null),
  )
}
