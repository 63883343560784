/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */

/**
 * An enumeration.
 */
export type FactoryProcess = typeof FactoryProcess[keyof typeof FactoryProcess];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FactoryProcess = {
  Anode_Coating: 'Anode Coating',
  Cathode_Coating: 'Cathode Coating',
  Anode_Pressing: 'Anode Pressing',
  Cathode_Pressing: 'Cathode Pressing',
  Anode_Calendering: 'Anode Calendering',
  Cathode_Calendering: 'Cathode Calendering',
  Anode_Sheet_Cutting: 'Anode Sheet Cutting',
  Cathode_Sheet_Cutting: 'Cathode Sheet Cutting',
  Anode_Notching: 'Anode Notching',
  Cathode_Notching: 'Cathode Notching',
  Sheet_Stacking: 'Sheet Stacking',
  Hot_Press: 'Hot Press',
  'Pre-Weld': 'Pre-Weld',
  XRJ: 'XRJ',
  Final_Weld: 'Final Weld',
  Insulation_Film_Wrapping: 'Insulation Film Wrapping',
  Cell_To_Can_Insertion: 'Cell To Can Insertion',
  Cell_Welded_To_Can: 'Cell Welded To Can',
  XRI: 'XRI',
  XRC: 'XRC',
  'Helium_Leak_#1': 'Helium Leak #1',
  'Efill_#1': 'Efill #1',
  Soaking: 'Soaking',
  Cooling_1: 'Cooling 1',
  Nail_Pulling: 'Nail Pulling',
  'Pre-charging': 'Pre-charging',
  Wiping: 'Wiping',
  Nail_Insertion: 'Nail Insertion',
  'Efill_#2': 'Efill #2',
  'IR/OCV_1': 'IR/OCV 1',
  HT_Aging_1: 'HT Aging 1',
  RT_Aging_1: 'RT Aging 1',
  'IR/OCV_2': 'IR/OCV 2',
  Formation_1: 'Formation 1',
  Cooling_2: 'Cooling 2',
  'IR/OCV_3': 'IR/OCV 3',
  HT_Aging_2: 'HT Aging 2',
  RT_Aging_2: 'RT Aging 2',
  'IR/OCV_4': 'IR/OCV 4',
  Degassing: 'Degassing',
  Final_Sealing: 'Final Sealing',
  Formation_2: 'Formation 2',
  DCIR: 'DCIR',
  Cell_Cleaning: 'Cell Cleaning',
  Wrapping: 'Wrapping',
  Final_Inspection: 'Final Inspection',
  Vision_Inspection: 'Vision Inspection',
  Manual_Inspection: 'Manual Inspection',
  DMC_Labelling: 'DMC Labelling',
  Packaging: 'Packaging',
} as const;
