import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Loader,
  Paper,
  Typography,
} from '@northvolt/ui'
import { useReadAttributeHistory } from 'client/wattson-client'
import ErrorCard from 'components/ErrorCard'
import { dateStringDisplayFormat } from 'components/Utils'

interface AttributeHistoryProps {
  attributeId: number
}

const AttributeHistory: React.FC<AttributeHistoryProps> = ({ attributeId }) => {
  const historyLoader = useReadAttributeHistory(attributeId, {
    query: { retry: 1 },
  })

  if (historyLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (historyLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={
          historyLoader.error?.response?.data?.detail?.toString() ||
          'History not found'
        }
      />
    )
  }
  if (!historyLoader.data.data.length) {
    return <Typography variant='overline'>No history found</Typography>
  }
  return (
    <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
      <Divider>
        <Typography variant='overline'>Change History</Typography>
      </Divider>
      <List>
        {historyLoader?.data.data.map(history => (
          <ListItem key={history.id + Math.random()}>
            <ListItemText>
              Version {history.transaction_id} -{' '}
              {dateStringDisplayFormat(history.issued_at)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default AttributeHistory
