import { Slider } from '@mui/material'
import { Stack, TextField } from '@northvolt/ui'
import type { AdhocFilter, AttributeStatistics } from 'client/model'
import { isFloat } from 'components/Utils'
import { useEffect, useState } from 'react'

export interface NumberRangeFilterControlProps {
  attributeStatistics: AttributeStatistics
  filter: AdhocFilter
  setFilter: (adhocFilter: AdhocFilter) => void
}

const NumberRangeFilterControl: React.FC<NumberRangeFilterControlProps> = ({
  attributeStatistics,
  filter,
  setFilter,
}) => {
  const calculateMinMax = (
    attributeStatistics: AttributeStatistics,
    filter: AdhocFilter,
  ) => {
    if (isFloat(filter.attribute)) {
      const fractionDigits = 4
      const valueStep =
        //@ts-ignore
        (attributeStatistics.double_max - attributeStatistics.double_min) / 1000
      //@ts-ignore
      const min = attributeStatistics.double_min - 500 * valueStep
      //@ts-ignore
      const max = attributeStatistics.double_max + 500 * valueStep

      return [valueStep, fractionDigits, min, max]
    } else {
      const margin = Math.round(
        //@ts-ignore
        (attributeStatistics.double_max - attributeStatistics.double_min) / 2,
      )
      //@ts-ignore
      const min = attributeStatistics.long_min - margin
      //@ts-ignore
      const max = attributeStatistics.long_max + margin
      return [1, 0, min, max]
    }
  }

  const [valueStep, fractionDigits, min, max] = calculateMinMax(
    attributeStatistics,
    filter,
  )

  const getLowerBound = () => {
    return filter.min_number !== undefined ? filter.min_number : min
  }

  const getUpperBound = () => {
    return filter.max_number !== undefined ? filter.max_number : max
  }

  const [lowerBoundText, setLowerBoundText] = useState(
    filter.min_number !== undefined
      ? filter.min_number.toFixed(fractionDigits)
      : min.toFixed(fractionDigits),
  )
  const [upperBoundText, setUpperBoundText] = useState(
    filter.max_number !== undefined
      ? filter.max_number.toFixed(fractionDigits)
      : max.toFixed(fractionDigits),
  )

  useEffect(() => {
    if (filter.min_number === undefined) {
      setFilter({ ...filter, min_number: min })
    }
    if (filter.max_number === undefined) {
      setFilter({ ...filter, max_number: max })
    }
  }, [attributeStatistics, filter])

  const setNewRangeValues = (
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (activeThumb === 0) {
      const val = Array.isArray(newValue) ? newValue[0] : newValue
      setFilter({ ...filter, min_number: val })
      setLowerBoundText(val.toFixed(fractionDigits))
    } else if (activeThumb === 1) {
      const val = Array.isArray(newValue) ? newValue[1] : newValue
      setFilter({ ...filter, max_number: val })
      setUpperBoundText(val.toFixed(fractionDigits))
    }
  }

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <TextField
        variant='outlined'
        sx={{ width: '20%' }}
        size='small'
        type='text'
        inputProps={{ pattern: '\\d*(\\.\\d+)?' }}
        value={lowerBoundText}
        error={
          !(
            !Number.isNaN(Number.parseFloat(lowerBoundText)) &&
            Number.parseFloat(lowerBoundText) <= getUpperBound()
          )
        }
        onChange={e => {
          setLowerBoundText(e.target.value)
        }}
        onBlur={e => {
          let newValue = Number.parseFloat(e.target.value)
          if (Number.isNaN(newValue) || newValue > getUpperBound()) {
            newValue = getUpperBound()
          } else if (newValue < min) {
            newValue = min
          }
          setLowerBoundText(newValue.toFixed(fractionDigits))
          setNewRangeValues(newValue, 0)
        }}
      />
      <Slider
        sx={{ width: '60%' }}
        size='small'
        value={[getLowerBound(), getUpperBound()]}
        onChange={(_, newValue, activeThumb) =>
          setNewRangeValues(newValue, activeThumb)
        }
        min={min}
        max={max}
        step={valueStep}
        valueLabelDisplay='auto'
        disableSwap
        valueLabelFormat={value => value.toLocaleString()}
      />
      <TextField
        variant='outlined'
        sx={{ width: '20%' }}
        size='small'
        type='text'
        error={
          !(
            !Number.isNaN(Number.parseFloat(upperBoundText)) &&
            Number.parseFloat(upperBoundText) >= getLowerBound()
          )
        }
        inputProps={{ pattern: '\\d*(\\.\\d+)?' }}
        value={upperBoundText}
        onChange={e => {
          setUpperBoundText(e.target.value)
        }}
        onBlur={e => {
          let newValue = Number.parseFloat(e.target.value)
          if (Number.isNaN(newValue) || newValue < getLowerBound()) {
            newValue = getLowerBound()
          } else if (newValue > max) {
            newValue = max
          }
          setUpperBoundText(newValue.toFixed(fractionDigits))
          setNewRangeValues(newValue, 1)
        }}
      />
    </Stack>
  )
}

export default NumberRangeFilterControl
