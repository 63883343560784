//@ts-nocheck - this file is not being checked for type errors
import CallMadeIcon from '@mui/icons-material/CallMade'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TuneIcon from '@mui/icons-material/Tune'
import { FormControl, ToggleButton } from '@mui/material'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Loader,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import {
  type AdhocFilter,
  CellKind,
  ExecutionStatus,
  FactoryProcess,
} from 'client/model'
import {
  useGetProcessEndedAtAttribute,
  useReadChangeDetectionRequestLight,
  useRunChangeDetection,
} from 'client/wattson-client'
import React, { useEffect, useState } from 'react'
import ChangeDetectionResultAccordion from './ChangeDetectionResultAccordion'

import DatetimeRangeFilterControl from 'components/Filter/DatetimeRangeFilterControl'

import { parseAsInteger, useQueryParam } from 'components/useQueryHook'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {children}
    </Box>
  )
}

export default function Page() {
  const [requestId, setRequestId] = useQueryParam(
    'requestId',
    undefined,
    parseAsInteger,
  )

  const [tabValue, setTabValue] = React.useState(0)
  const [dataSelectionExpanded, setDataSelectionExpanded] =
    useState<boolean>(true)
  const [resultsLoaded, setResultsLoaded] = useState<boolean>(false)
  const [process, setProcess] = useState<FactoryProcess>(
    FactoryProcess.Final_Inspection,
  )
  const [cellKind, setCellKind] = useState<CellKind>(CellKind.PPE_CV)
  const [timeFilter, setTimeFilter] = useState<AdhocFilter | undefined>(
    undefined,
  )

  const [showTrendline, setShowTrendline] = useState<boolean>(false)
  const [showOutliers, setShowOutliers] = useState<boolean>(true)

  const handleTabChange = (_: any, newValue: number) => {
    setTabValue(newValue)
  }

  const changeDetector = useRunChangeDetection()
  const changeDetectionRequest = useReadChangeDetectionRequestLight(requestId, {
    query: {
      enabled: requestId !== undefined && requestId !== null,
      retry: 1,
      refetchIntervalInBackground: true,
      refetchInterval: (data, query) => {
        if (
          query.state.status !== 'error' &&
          data?.data?.status === ExecutionStatus.RUNNING
        ) {
          return 8000
        }
        return false
      },
    },
  })
  const timeAttributeLoader = useGetProcessEndedAtAttribute()

  useEffect(() => {
    if (changeDetector?.data?.data?.id) {
      setRequestId(changeDetector.data.data.id)
    }
  }, [changeDetector?.data?.data?.id])

  const isError =
    changeDetector.isError ||
    changeDetectionRequest.isError ||
    changeDetectionRequest.data?.data?.status === ExecutionStatus.FAILURE
  const isSuccess =
    changeDetectionRequest.isSuccess &&
    changeDetectionRequest.data?.data?.status === ExecutionStatus.SUCCESS &&
    changeDetectionRequest.data?.data?.dataframe
  const isLoading =
    !isError &&
    !isSuccess &&
    (changeDetector.isLoading ||
      changeDetectionRequest.isFetching ||
      changeDetectionRequest.data?.data?.status === ExecutionStatus.RUNNING)

  const tableName = request_id => `change_detector_${request_id}`
  useEffect(() => {
    const df = changeDetectionRequest?.data?.data?.dataframe
    if (df && df.length > 0) {
      const duckdb = vg.wasmConnector()
      vg.coordinator().databaseConnector(duckdb)
      vg.coordinator()
        .exec([vg.loadExtension('icu')])
        .then(() => {
          duckdb
            .getDuckDB()
            .then(db => {
              db.registerFileText('rows.json', JSON.stringify(df))
            })
            .then(() => {
              duckdb.getConnection().then(con => {
                con
                  .insertJSONFromPath('rows.json', {
                    name: tableName(changeDetectionRequest?.data?.data?.id),
                  })
                  .then(() => {
                    setResultsLoaded(true)
                    setDataSelectionExpanded(false)
                  })
              })
            })
        })
    } else {
      setResultsLoaded(false)
    }
  }, [changeDetectionRequest?.data?.data?.dataframe, requestId])

  useEffect(() => {
    const attribute = timeAttributeLoader?.data?.data
    if (attribute) {
      setTimeFilter({
        ...timeFilter,
        attribute: attribute,
        attribute_id: attribute.id,
        type: 'datetime_range',
      })
    } else {
      setTimeFilter({
        ...timeFilter,
        attribute: undefined,
        attribute_id: undefined,
      })
    }
  }, [timeAttributeLoader?.data?.data])

  useEffect(() => {
    setTimeFilter({
      ...timeFilter,
      attribute: undefined,
      attribute_id: undefined,
    })
    if (process) {
      timeAttributeLoader.mutate({ params: { process: process } })
    }
  }, [process])

  const runChangeDetector = () => {
    setResultsLoaded(false)
    changeDetector.mutate({
      data: timeFilter,
      params: {
        process: process,
        cell_kind: cellKind,
      },
    })
  }

  const disabledProcesses: FactoryProcess[] = [
    FactoryProcess.Anode_Coating,
    FactoryProcess.Cathode_Coating,
    FactoryProcess.Anode_Pressing,
    FactoryProcess.Cathode_Pressing,
    FactoryProcess.Anode_Calendering,
    FactoryProcess.Cathode_Calendering,
    FactoryProcess.Anode_Notching,
    FactoryProcess.Cathode_Notching,
    FactoryProcess.Anode_Sheet_Cutting,
    FactoryProcess.Cathode_Sheet_Cutting,
    FactoryProcess.Sheet_Stacking,
    FactoryProcess.Hot_Press,
    FactoryProcess['Pre-Weld'],
    FactoryProcess.XRJ,
    FactoryProcess.Visual_Inspection,
    FactoryProcess.Manual_Inspection,
    FactoryProcess.DMC_Labelling,
    FactoryProcess.Packaging,
  ]

  const processSelection = () => {
    return (
      <Grid container spacing={2} columns={{ xs: 2, md: 8 }}>
        <Grid xs={2}>
          <FormControl fullWidth>
            <Select
              id='process'
              label='Process'
              size='small'
              value={process}
              onChange={({ target: { value } }) =>
                setProcess(value as FactoryProcess)
              }
              fullWidth
              required>
              {Object.values(FactoryProcess).map(process => (
                <MenuItem
                  key={process}
                  value={process}
                  disabled={disabledProcesses.includes(process)}>
                  {process}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2}>
          <FormControl fullWidth>
            <Select
              id='cell_kind'
              label='Cell Kind'
              size='small'
              value={cellKind}
              onChange={({ target: { value } }) =>
                setCellKind(value as CellKind)
              }
              fullWidth
              required>
              {Object.values(CellKind).map(cellKind => (
                <MenuItem key={cellKind} value={cellKind}>
                  {cellKind}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2}>
          <DatetimeRangeFilterControl
            filter={timeFilter}
            setFilter={setTimeFilter}
            attributeStatistics={undefined}
            disabled={timeFilter === undefined}
          />
        </Grid>
        <Grid xs={2}>
          <Button
            size='medium'
            onClick={runChangeDetector}
            sx={{ width: '100%' }}
            disabled={
              timeFilter === undefined ||
              process === undefined ||
              cellKind === undefined
            }>
            Find Changes!
          </Button>
        </Grid>
      </Grid>
    )
  }

  const plotControls = () => {
    return (
      isSuccess &&
      resultsLoaded && (
        <Paper sx={{ px: 2, py: 1 }}>
          <Grid container spacing={2} columns={{ xs: 2, md: 4 }}>
            <Grid xs={1}>
              <Box sx={{ height: '100%' }} display='flex' alignItems='center'>
                <Typography variant='subtitleMedium'>
                  <strong>Plot Controls</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid xs={1}>
              <ToggleButton
                selected={showTrendline}
                onClick={() => setShowTrendline(!showTrendline)}
                value='check'
                size='small'
                fullWidth
                sx={{ height: '100%' }}>
                <CallMadeIcon />
                <Typography variant='captionSmall' sx={{ pl: 1 }}>
                  Show Trendline
                </Typography>
              </ToggleButton>
            </Grid>
            <Grid xs={1}>
              <ToggleButton
                selected={showOutliers}
                onClick={() => setShowOutliers(!showOutliers)}
                value='check'
                size='small'
                fullWidth
                sx={{ height: '100%' }}>
                <CrisisAlertIcon />
                <Typography variant='captionSmall' sx={{ pl: 1 }}>
                  Show Outliers
                </Typography>
              </ToggleButton>
            </Grid>
          </Grid>
        </Paper>
      )
    )
  }

  const resultContent = () => {
    if (isLoading) {
      return (
        <Box display='flex' justifyContent='center'>
          <Loader size='large' type='linear' />
        </Box>
      )
    }
    if (isError) {
      return (
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='headlineSmall'>
            Failed to Run Change Detection
          </Typography>
          <Typography variant='bodyMedium'>
            {changeDetector.error?.message}
          </Typography>
          <Typography variant='bodyMedium'>
            {changeDetectionRequest.error?.response?.data?.detail}
          </Typography>
        </Box>
      )
    }
    if (isSuccess && resultsLoaded) {
      return (
        <Stack divider={<Divider />}>
          {changeDetectionRequest.data.data.results.map(
            (changeDetectionResult, index: number) => (
              <ChangeDetectionResultAccordion
                key={index}
                changeDetectionResult={changeDetectionResult}
                tableName={tableName(changeDetectionRequest?.data?.data?.id)}
                showTrendline={showTrendline}
                showOutliers={showOutliers}
              />
            ),
          )}
        </Stack>
      )
    }
    return <></>
  }

  return (
    <Stack spacing={1} display='flex' sx={{ height: '100%' }}>
      <Accordion
        expanded={dataSelectionExpanded}
        disableGutters
        sx={{ m: 0, p: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setDataSelectionExpanded(!dataSelectionExpanded)}
          sx={{ my: 0, py: 0 }}>
          <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
            <TrackChangesIcon />
          </Box>
          <Typography variant='headlineSmall' sx={{ my: 0, py: 0 }}>
            Change Detector
          </Typography>
        </AccordionSummary>
        <Box sx={{ my: 0, py: 0, px: 2, pb: 2 }}>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{ pb: 4 }}>
            <Tab
              label='By Process'
              icon={<PrecisionManufacturingIcon />}
              iconPosition='start'
              sx={{ minHeight: 30 }}
            />
            <Tab
              label='Custom'
              icon={<TuneIcon />}
              iconPosition='start'
              sx={{ minHeight: 30 }}
              disabled
            />
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            {processSelection()}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1} />
        </Box>
      </Accordion>
      {plotControls()}
      {resultContent()}
    </Stack>
  )
}
