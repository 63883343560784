import BalanceIcon from '@mui/icons-material/Balance'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import ChecklistIcon from '@mui/icons-material/Checklist'
import DateRangeIcon from '@mui/icons-material/DateRange'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Chip } from '@northvolt/ui'
import { type FilterLight, FilterType } from 'client/model'
import type React from 'react'

import { dateStringDisplayFormat } from './../Utils'

interface FilterChipProps {
  filter: FilterLight
}

const FilterChip: React.FC<FilterChipProps> = ({ filter }) => {
  function resolveType() {
    if (
      filter.multiple &&
      filter.multiple_values &&
      (filter.type === FilterType.equal || filter.type === FilterType.not_equal)
    ) {
      const equality = filter.type === FilterType.equal ? 'is in' : 'is not in'
      const strValues = filter.multiple_values.map(el => el.value)
      const setStr =
        filter.multiple_values.length > 10
          ? `${strValues.slice(0, 10).join(', ')}...`
          : strValues.join(', ')
      return {
        label: `${filter.attribute.unique_name} ${equality} { ${setStr} }`,
        title: `Materials where the ${filter.attribute.unique_name} attribute is one of the following: ${setStr}`,
        icon: <BalanceIcon />,
      }
    }
    if (
      !filter.multiple &&
      (filter.type === FilterType.equal || filter.type === FilterType.not_equal)
    ) {
      const equality = filter.type === FilterType.equal ? '=' : '!='
      const textEquality =
        filter.type === FilterType.equal ? 'is equal' : 'is not equal'
      return {
        label: `${filter.attribute.unique_name} ${equality} ${filter.value}`,
        title: `Materials where the ${filter.attribute.unique_name} attribute ${textEquality} to ${filter.value}`,
        icon: <BalanceIcon />,
      }
    }
    if (filter.type === FilterType.number_range) {
      return {
        label: `${filter.attribute.unique_name} ∈ [${filter.min_number}, ${filter.max_number})`,
        title: `Materials where the ${filter.attribute.unique_name} attribute is between ${filter.min_number} (inclusive) and ${filter.max_number} (exclusive)`,
        icon: <LinearScaleIcon />,
      }
    }
    if (filter.type === FilterType.datetime_range) {
      return {
        label: `${filter.attribute.unique_name} ∈ [${dateStringDisplayFormat(
          filter.min_datetime,
          true,
        )}, ${dateStringDisplayFormat(filter.max_datetime, true)})`,
        title: `Materials where the ${filter.attribute.unique_name} attribute is between ${dateStringDisplayFormat(
          filter.min_datetime,
          true,
        )} (inclusive) and ${dateStringDisplayFormat(filter.max_datetime, true)} (exclusive)`,
        icon: <DateRangeIcon />,
      }
    }
    if (filter.type === FilterType.not_null) {
      return {
        label: `${filter.attribute.unique_name} is not missing`,
        title: `Materials where the ${filter.attribute.unique_name} attribute is not null`,
        icon: <ChecklistIcon />,
      }
    }
    if (filter.type === FilterType.null) {
      return {
        label: `${filter.attribute.unique_name} is missing`,
        title: `Materials where the ${filter.attribute.unique_name} attribute is null`,
        icon: <CancelPresentationIcon />,
      }
    }
    return {
      label: `${filter.attribute.unique_name} ${filter.type} ${filter.value}`,
      title: `Unknown filter type ${filter.type} on attribute ${filter.attribute.unique_name}`,
      icon: <QuestionMarkIcon />,
    }
  }

  const { label, title, icon } = resolveType()

  return (
    <Chip
      title={title}
      label={label}
      size='small'
      sx={{ m: 0.25, maxWidth: 500 }}
      color='secondary'
      icon={icon}
      variant='outlined'
    />
  )
}

export default FilterChip
