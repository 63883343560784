import EqualizerIcon from '@mui/icons-material/Equalizer'
import {
  Box,
  Divider,
  Grid,
  Table as MuiTable,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import { useEffect, useReducer, useRef, useState } from 'react'

import { type Table, tableToIPC } from 'apache-arrow'
import { NumericFormat } from 'react-number-format'

import { Typography } from '@mui/material'
import AttributeSelector from 'components/Attribute/AttributeSelector'
import DataLoader from 'components/DataLoader/DataLoader'
import type {
  SelectableAttribute,
  SelectableAttributeCollection,
} from 'components/DataLoader/DataLoaderTypes'
import { DataType } from 'components/Utils'
import PlotBox from './PlotBox'
import PlotHist, { type Stats } from './PlotHist'

export default function Page() {
  const [renderId, forceUpdate] = useReducer(x => x + 1, 0)

  const [attributeCollections, setAttributeCollections] = useState<
    SelectableAttributeCollection[]
  >([])
  const [table, setTable] = useState<Table | undefined>(undefined)
  const [dataSelectionExpanded, setDataSelectionExpanded] =
    useState<boolean>(true)

  const [selectedAttr, setSelectedAttr] = useState<SelectableAttribute>()
  const [selectedColorAttr, setSelectedColorAttr] =
    useState<SelectableAttribute>()
  const [selectedTimeAttr, setSelectedTimeAttr] =
    useState<SelectableAttribute>()
  const [LSL, setLSL] = useState<number | undefined>(undefined)
  const [USL, setUSL] = useState<number | undefined>(undefined)

  const [stats, setStats] = useState<Stats[]>([])

  const [plotAreaWidth, setPlotAreaWidth] = useState<number>(600)
  const [plotAreaHeight, setPlotAreaHeight] = useState<number>(600)

  const $filterParam = useRef<any>(null)

  if (!$filterParam.current) {
    $filterParam.current = vg.Selection.single()
  }

  const resetPlotInteractions = () => {
    if ($filterParam.current) {
      $filterParam.current._value = []
      $filterParam.current._resolved = []
    }
    forceUpdate()
  }

  const plotContainerRef = useRef<any>(null)

  useEffect(() => {
    if (table?.numRows > 0) {
      const duckdb = vg.wasmConnector()
      duckdb.getConnection().then(con => {
        const ipc = tableToIPC(table)
        con.insertArrowFromIPCStream(ipc, { name: 'active_table' }).then(() => {
          setDataSelectionExpanded(false)
        })
        vg.coordinator().databaseConnector(duckdb)
        vg.coordinator().exec([vg.loadExtension('icu')])
      })
    }
  }, [table])

  useEffect(() => {
    if (plotContainerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (plotContainerRef?.current !== null) {
          if (plotContainerRef.current.offsetWidth !== plotAreaWidth) {
            setPlotAreaWidth(plotContainerRef.current.offsetWidth)
          }
          if (plotContainerRef.current.offsetHeight !== plotAreaHeight) {
            setPlotAreaHeight(plotContainerRef.current.offsetHeight)
          }
        }
      })
      resizeObserver.observe(plotContainerRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [plotContainerRef.current])

  useEffect(() => {
    resetPlotInteractions()
  }, [selectedTimeAttr, selectedColorAttr, LSL, USL])

  useEffect(() => {
    setLSL(undefined)
    setUSL(undefined)
  }, [selectedAttr])

  const plotControls = (
    <>
      <Grid container spacing={{ xs: 1 }} columns={{ xs: 4, md: 2 }}>
        <Grid xs={2}>
          <AttributeSelector
            label='Main Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedAttr ? [selectedAttr] : []}
            setSelectedAttributes={attr => setSelectedAttr(attr[0])}
            disabled={table?.numRows === 0}
            dataTypeWhitelist={[DataType.DOUBLE]}
          />
        </Grid>
        <Grid xs={1}>
          <NumericFormat
            label='LSL'
            customInput={TextField}
            variant='outlined'
            size='small'
            value={LSL}
            onChange={e =>
              setLSL(Number.parseFloat(e.target.value) || undefined)
            }
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid xs={1}>
          <NumericFormat
            label='USL'
            customInput={TextField}
            variant='outlined'
            size='small'
            value={USL}
            onChange={e =>
              setUSL(Number.parseFloat(e.target.value) || undefined)
            }
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='Color Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedColorAttr ? [selectedColorAttr] : []}
            setSelectedAttributes={attr => setSelectedColorAttr(attr[0])}
            disabled={table?.numRows === 0}
            dataTypeWhitelist={[DataType.STRING, DataType.BOOLEAN]}
          />
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='Time Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedTimeAttr ? [selectedTimeAttr] : []}
            setSelectedAttributes={attr => setSelectedTimeAttr(attr[0])}
            disabled={table?.numRows === 0}
            dataTypeWhitelist={[DataType.TIMESTAMP, DataType.DATE]}
          />
        </Grid>
      </Grid>
    </>
  )

  const statsTable = stats.length ? (
    <>
      <Divider>
        <Typography variant='overline'>Stats</Typography>
      </Divider>
      <TableContainer
        sx={{ border: 1, borderRadius: 1, borderColor: 'grey.600', py: 2 }}>
        <MuiTable size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell align='right'>Pp</TableCell>
              <TableCell align='right'>Ppk</TableCell>
              <TableCell align='right'>Cp</TableCell>
              <TableCell align='right'>Cpk</TableCell>
              <TableCell align='right'>N</TableCell>
              <TableCell align='right'>mean</TableCell>
              <TableCell align='right'>std</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats
              .sort((a, b) => a.color.localeCompare(b.color))
              .map(row => (
                <TableRow
                  key={row.color}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {row.color}
                  </TableCell>
                  <TableCell align='right'>
                    {row.pp ? row.pp.toFixed(3) : '-'}
                  </TableCell>
                  <TableCell align='right'>
                    {row.ppk ? row.ppk.toFixed(3) : '-'}
                  </TableCell>
                  <TableCell align='right'>
                    {row.cp ? row.cp.toFixed(3) : '-'}
                  </TableCell>
                  <TableCell align='right'>
                    {row.cpk ? row.cpk.toFixed(3) : '-'}
                  </TableCell>
                  <TableCell align='right'>{row.n}</TableCell>
                  <TableCell align='right'>
                    {row.mean ? row.mean.toFixed(4) : ':'}
                  </TableCell>
                  <TableCell align='right'>
                    {row.std_dev ? row.std_dev.toFixed(3) : ':'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  ) : (
    <></>
  )

  return (
    <Stack spacing={2} display='flex' sx={{ height: '100%' }}>
      <DataLoader
        title={'Process Capability'}
        table={table}
        setTable={setTable}
        setAttributeCollections={setAttributeCollections}
        expanded={dataSelectionExpanded}
        setExpanded={setDataSelectionExpanded}
        icon={<EqualizerIcon />}
      />

      {table?.numRows > 0 && (
        <Paper
          elevation={1}
          sx={{
            p: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row-reverse' }}
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ width: { xs: '100%', md: '30%' } }}>
              {plotControls}
              {statsTable}
            </Box>
            <Box sx={{ width: '100%', height: '100%' }} ref={plotContainerRef}>
              {selectedAttr && (
                <PlotHist
                  attribute={selectedAttr}
                  colorAttr={selectedColorAttr}
                  timeAttr={selectedTimeAttr}
                  LSL={LSL}
                  USL={USL}
                  width={plotAreaWidth}
                  height={plotAreaHeight / (selectedTimeAttr ? 2 : 1)}
                  $filterParam={$filterParam.current}
                  setStats={setStats}
                  renderId={renderId}
                />
              )}
              {selectedAttr && selectedTimeAttr && (
                <PlotBox
                  xAttribute={selectedTimeAttr}
                  yAttribute={selectedAttr}
                  LSL={LSL}
                  USL={USL}
                  width={plotAreaWidth}
                  height={plotAreaHeight / 2}
                  $filterParam={$filterParam.current}
                  renderId={renderId}
                />
              )}
            </Box>
          </Stack>
        </Paper>
      )}
    </Stack>
  )
}
