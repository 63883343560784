import { Box, useTheme } from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import type { ChangeDetectionResult } from 'client/model'
import { dateToString } from 'components/Utils'
import { useEffect, useRef, useState } from 'react'

interface ChangeDetectionResultPlotProps {
  changeDetectionResult: ChangeDetectionResult
  tableName: string
  showTrendline: boolean
  showOutliers: boolean
}

const ChangeDetectionResultPlot: React.FC<ChangeDetectionResultPlotProps> = ({
  changeDetectionResult,
  tableName,
  showTrendline,
  showOutliers,
}) => {
  const plotRef = useRef<any>(null)
  const [width, setWidth] = useState<number>()
  const theme = useTheme()
  const orange = theme.palette.warning.main
  const red = theme.palette.error.main

  useEffect(() => {
    if (plotRef) {
      const changePointDates = changeDetectionResult.change_points.map(
        cp => new Date(cp.changed_at),
      )
      const $brush = vg.Selection.single()
      const $brushEnd = vg.Param.value(null)
      $brush.addEventListener('value', (brushValues: any) => {
        if (brushValues && brushValues.length > 1) {
          $brushEnd.update(brushValues[1])
        }
      })

      const brushToDateString = (brushParam: any, idx: number) => {
        if (brushParam?.value && brushParam.value.length > idx)
          return dateToString(brushParam.value[idx])
        return ''
      }

      const outlierIds = changeDetectionResult.outliers.map(o => `'${o.nv_id}'`)
      const isOutlier = vg.sql`${vg.column('core_cells_cell_id')} IN (${outlierIds.join(', ')})`
      const outlierFill =
        showOutliers && outlierIds.length > 0 ? isOutlier : 'black'
      const $colors = vg.Param.array(['black', red])
      const $domain = vg.Param.array([false, true])

      const plot = vg.plot(
        vg.marginTop(30),
        vg.line(vg.from(tableName), {
          x: vg.sql`CAST(${vg.column(changeDetectionResult.time_attribute.unique_name)} AS TIMESTAMP)`,
          y: changeDetectionResult.attribute.unique_name,
          // title: vg.sql`concat('${changeDetectionResult.time_attribute.column_name}', ': ', CAST(${vg.column(changeDetectionResult.time_attribute.unique_name)} AS DATE), '\n', 'asdas')`,
          tip: true,
        }),
        vg.dot(vg.from(tableName), {
          x: vg.sql`CAST(${vg.column(changeDetectionResult.time_attribute.unique_name)} AS TIMESTAMP)`,
          y: changeDetectionResult.attribute.unique_name,
          r: 3.5,
          fill: outlierFill,
        }),
        ...(showTrendline
          ? [
              vg.regressionY(vg.from(tableName), {
                x: vg.sql`epoch_ms(CAST(${vg.column(changeDetectionResult.time_attribute.unique_name)} AS TIMESTAMP))`,
                y: changeDetectionResult.attribute.unique_name,
                stroke: orange,
              }),
            ]
          : []),
        vg.intervalX({ as: $brush }),
        vg.textX([$brush], {
          x: $brush,
          text: p => brushToDateString(p, 0),
          frameAnchor: 'top',
          lineAnchor: 'bottom',
          dy: -5,
        }),
        vg.textX([$brushEnd], {
          x: $brushEnd,
          text: p => dateToString(p?.value),
          frameAnchor: 'top',
          lineAnchor: 'bottom',
          dy: -20,
        }),
        vg.colorDomain($domain),
        vg.colorRange($colors),
        vg.ruleX(changePointDates, { stroke: orange, strokeWidth: 2 }),
        vg.width(width),
        vg.height(300),
        vg.gridX(),
        vg.gridY(),
      )

      plotRef.current.appendChild(plot)
      return () => {
        if (plotRef.current) {
          plotRef.current.removeChild(plot)
        }
      }
    }
  }, [changeDetectionResult, width, showTrendline, showOutliers])

  useEffect(() => {
    if (plotRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (plotRef.current !== null && plotRef.current.offsetWidth !== width) {
          setWidth(plotRef.current.offsetWidth)
        }
      })
      resizeObserver.observe(plotRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [plotRef.current])

  return <Box ref={plotRef} sx={{ width: '100%' }} />
}

export default ChangeDetectionResultPlot
