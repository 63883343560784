import { Box, useTheme } from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import type { SelectableAttribute } from 'components/DataLoader/DataLoaderTypes'
import { useEffect, useRef, useState } from 'react'
import { colorScheme } from 'routes/tools/plotter/PlotUtils'

interface PlotBoxProps {
  xAttribute: SelectableAttribute
  yAttribute: SelectableAttribute
  LSL?: number
  USL?: number
  width: number
  height: number
  $filterParam: any
  renderId?: number
}

const PlotBox: React.FC<PlotBoxProps> = ({
  xAttribute,
  yAttribute,
  LSL,
  USL,
  width,
  height,
  $filterParam,
  renderId,
}) => {
  const [plot, setPlot] = useState<any>(null)

  const theme = useTheme()
  const themeGrey = theme.palette.grey[700]

  const plotRef = useRef<any>(null)

  useEffect(() => {
    if (
      xAttribute &&
      yAttribute &&
      width &&
      height < Number.POSITIVE_INFINITY
    ) {
      const updatePlot = async () => {
        const p = getPlot(
          xAttribute,
          yAttribute,
          LSL,
          USL,
          $filterParam,
          width,
          height,
          themeGrey,
        )
        setPlot(p)
      }
      updatePlot()
    } else {
      setPlot(vg.vspace(0))
    }
  }, [xAttribute, yAttribute, LSL, USL, width, height, themeGrey, renderId])

  useEffect(() => {
    if (plotRef.current && plot) {
      plotRef.current.appendChild(plot)
      return () => {
        if (plotRef.current) {
          plotRef.current.removeChild(plot)
        }
      }
    }
  }, [plot, plotRef])

  return <Box ref={plotRef} />
}

export default PlotBox

function getPlot(
  xAttribute: SelectableAttribute,
  yAttribute: SelectableAttribute,
  LSL: number | undefined,
  USL: number | undefined,
  $filterParam: any,
  width: number,
  height: number,
  themeGrey: string,
) {
  return vg.plot(
    vg.ruleX(vg.from('active_table', { filterBy: $filterParam }), {
      x: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP) + INTERVAL 12 HOUR`,
      y1: vg.agg`${vg.quantile(yAttribute?.unique_name, 0.25)} - 1.5 * (${vg.quantile(
        yAttribute?.unique_name,
        0.75,
      )} - ${vg.quantile(yAttribute?.unique_name, 0.25)})`,
      y2: vg.agg`${vg.quantile(yAttribute?.unique_name, 0.75)} + 1.5 * (${vg.quantile(
        yAttribute?.unique_name,
        0.75,
      )} - ${vg.quantile(yAttribute?.unique_name, 0.25)})`,
      stroke: 'black',
    }),
    vg.ruleY(vg.from('active_table', { filterBy: $filterParam }), {
      x1: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP)`,
      x2: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP) + INTERVAL 1 DAY`,
      y: vg.agg`${vg.quantile(yAttribute?.unique_name, 0.25)} - 1.5 * (${vg.quantile(
        yAttribute?.unique_name,
        0.75,
      )} - ${vg.quantile(yAttribute?.unique_name, 0.25)})`,
      stroke: 'black',
      insetLeft: 5,
      insetRight: 5,
    }),
    vg.ruleY(vg.from('active_table', { filterBy: $filterParam }), {
      x1: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP)`,
      x2: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP) + INTERVAL 1 DAY`,
      y: vg.agg`${vg.quantile(yAttribute?.unique_name, 0.75)} + 1.5 * (${vg.quantile(
        yAttribute?.unique_name,
        0.75,
      )} - ${vg.quantile(yAttribute?.unique_name, 0.25)})`,
      stroke: 'black',
      insetLeft: 5,
      insetRight: 5,
    }),
    vg.rect(vg.from('active_table', { filterBy: $filterParam }), {
      x1: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP)`,
      x2: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP) + INTERVAL 1 DAY`,
      y1: vg.quantile(yAttribute?.unique_name, 0.25),
      y2: vg.quantile(yAttribute?.unique_name, 0.75),
      stroke: 'black',
      fill: themeGrey,
      tip: true,
      rx: 2,
      insetLeft: 5,
      insetRight: 5,
    }),
    vg.rect(vg.from('active_table', { filterBy: $filterParam }), {
      x1: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP)`,
      x2: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP) + INTERVAL 1 DAY`,
      y1: vg.quantile(yAttribute?.unique_name, 0.25),
      y2: vg.quantile(yAttribute?.unique_name, 0.75),
      stroke: 'black',
      fill: themeGrey,
      tip: true,
      rx: 2,
      insetLeft: 5,
      insetRight: 5,
    }),
    vg.ruleY(vg.from('active_table', { filterBy: $filterParam }), {
      x1: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP)`,
      x2: vg.sql`CAST(date_trunc('day', ${vg.column(xAttribute?.unique_name)}) AS TIMESTAMP) + INTERVAL 1 DAY`,
      y: vg.quantile(yAttribute?.unique_name, 0.5),
      stroke: 'black',
      insetLeft: 5,
      insetRight: 5,
    }),
    ...(LSL
      ? [
          vg.ruleY([LSL], { stroke: 'red', strokeWidth: 1.5 }),
          vg.textY([LSL], {
            y: LSL,
            fill: 'red',
            frameAnchor: 'right',
            dy: -10,
            fontSize: 16,
            text: _p => 'LSL',
          }),
        ]
      : []),
    ...(USL
      ? [
          vg.ruleY([USL], { stroke: 'red', strokeWidth: 1.5 }),
          vg.textY([USL], {
            y: USL,
            fill: 'red',
            frameAnchor: 'right',
            dy: -10,
            fontSize: 16,
            text: _p => 'USL',
          }),
        ]
      : []),
    vg.marginBottom(40),
    vg.width(width),
    vg.height(height),
    vg.colorScheme(colorScheme),
    vg.colorDomain(vg.Fixed),
    vg.xLabel(`${xAttribute.column_name} →`),
    vg.yLabel(`↑ ${yAttribute.column_name}`),
    vg.xGrid(true),
    vg.xScale('utc'),
  )
}
