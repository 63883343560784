import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined'
import { Stack } from '@mui/material'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@northvolt/ui'
import type { AttributeCollection } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import { dateStringDisplayFormat } from './../Utils'

interface AttributeCollectionCardProps {
  attributeCollection: AttributeCollection
}

const AttributeCollectionCard: React.FC<AttributeCollectionCardProps> = ({
  attributeCollection,
}) => {
  const subHeader = `Created by ${attributeCollection?.author.full_name} on ${dateStringDisplayFormat(attributeCollection?.created_at, true)}`
  return (
    <Card
      sx={{
        minHeight: 200,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Link
        to={`/atlas/attribute-collections/${attributeCollection.id}`}
        style={{ textDecoration: 'none', color: 'inherit' }}>
        <Stack direction='row' spacing={1} alignItems='top'>
          <ViewColumnOutlinedIcon
            sx={{ mt: 1.25, ml: 1 }}
            style={{ fontSize: 30 }}
          />
          <CardHeader
            title={attributeCollection?.name}
            subheader={subHeader}
            sx={{ pt: 1, px: 0 }}
          />
        </Stack>
      </Link>
      <CardContent>
        <Divider>
          <Typography variant='overline'>Description</Typography>
        </Divider>
        <Typography variant='bodySmall'>
          {attributeCollection?.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default AttributeCollectionCard
