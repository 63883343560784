import { FormControl, InputLabel, useTheme } from '@mui/material'
import { Grid } from '@northvolt/ui'
import type { Filter, MaterialType } from 'client/model'
import type React from 'react'
import FilterWidget from './FilterWidget'

interface FilterBoxProps {
  filters: Filter[]
  setFilters: (filters: Filter[]) => void
  grain: MaterialType
}

const FilterBox: React.FC<FilterBoxProps> = ({
  filters,
  setFilters,
  grain,
}) => {
  const theme = useTheme()

  return (
    <FormControl fullWidth sx={{ height: '100%' }}>
      <InputLabel
        shrink
        id='filters-label'
        style={{ backgroundColor: 'white' }}>
        {' '}
        Filters{' '}
      </InputLabel>
      <Grid
        container
        columns={12}
        border={0.5}
        borderRadius={1}
        borderColor={theme.palette.grey[700]}>
        {filters.map((filter, index) => (
          <Grid key={index} xs={12} lg={6} xl={4}>
            <FilterWidget
              key={index}
              filter={filter}
              setFilter={filter => {
                if (!filter) {
                  const newFilters = [...filters]
                  newFilters.splice(index, 1)
                  setFilters(newFilters)
                  return
                }
                const newFilters = [...filters]
                newFilters[index] = filter as Filter
                setFilters(newFilters)
              }}
              grain={grain}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  )
}

export default FilterBox
