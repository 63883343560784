import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Pagination } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@northvolt/ui'
import type {
  useSearchAttributeCollectionsByTags,
  useSearchAttributesByTags,
  useSearchDatasetsByTags,
  useSearchSamplesByTags,
} from 'client/wattson-client'
import { parseAsInteger, useQueryParam } from 'components/useQueryHook'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

interface ResultAccordionProps {
  searchTagIds: number[]
  expanded: boolean
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void
  name: string
  title: string
  tagSearch:
    | typeof useSearchDatasetsByTags
    | typeof useSearchSamplesByTags
    | typeof useSearchAttributeCollectionsByTags
    | typeof useSearchAttributesByTags
  getResultItem: (r: any) => JSX.Element
  itemsPerPage: number
  icon: React.ReactNode
  delay?: number
}

const ResultAccordion: React.FC<ResultAccordionProps> = ({
  searchTagIds,
  expanded,
  onChange,
  name,
  title,
  tagSearch,
  getResultItem,
  itemsPerPage,
  icon,
  delay,
}) => {
  const [page, setPage] = useQueryParam(`${name}Page`, 1, parseAsInteger)
  const search = tagSearch()

  const fetchResults = () => {
    if (searchTagIds?.length > 0) {
      search.mutate({
        data: searchTagIds,
        params: { limit: itemsPerPage, offset: (page - 1) * itemsPerPage },
      })
    } else {
      search.reset()
    }
  }

  useDeepCompareEffect(() => {
    fetchResults()
    setPage(1)
  }, [searchTagIds])

  useEffect(() => {
    fetchResults()
  }, [page])

  const count = search.data?.data?.count || 0

  const getSummary = () => {
    if (search.isLoading) {
      return <CircularProgress color='inherit' size={20} />
    } else if (search.isError) {
      return (
        <Box sx={{ overflow: 'auto' }}>
          <strong>
            <Typography variant='headlineSmall' color='error'>
              Error
            </Typography>
          </strong>
          <Typography variant='bodyMedium'>{search.error?.message}</Typography>
        </Box>
      )
    } else {
      return (
        <Typography
          variant='captionSmall'
          color='text.secondary'
          sx={{ ml: 1 }}>
          {count} results
        </Typography>
      )
    }
  }

  const getDetails = () => {
    const results = search.data?.data.items || []
    if (search.isLoading) {
      ;<Box
        display='flex'
        justifyContent='center'
        sx={{ py: 1, width: '100%' }}>
        <CircularProgress />
      </Box>
    } else if (search.isError) {
      return (
        <Box sx={{ overflow: 'auto' }}>
          <Typography variant='overline' color='error'>
            Error
          </Typography>
          <Typography variant='bodyMedium'>{search.error?.message}</Typography>
        </Box>
      )
    } else if (search.isSuccess && results.length === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}>
          <Typography variant='overline'>No results found</Typography>
        </Box>
      )
    } else if (search.isSuccess && results.length !== 0) {
      return (
        <Grid container spacing={1} columns={12}>
          {results.map((r: any, index: number) => (
            <Grid xs={12} md={6} xl={4} key={r.id} sx={{ height: '100%' }}>
              <motion.div
                initial={{ opacity: 0, translateX: -25 }}
                animate={{ translateX: 0, opacity: 1, from: 0, to: 1 }}
                transition={{ delay: index / 10, ease: 'easeIn' }}>
                {getResultItem(r)}
              </motion.div>
            </Grid>
          ))}
          <Grid xs={12}>
            <Pagination
              count={Math.trunc(count / itemsPerPage) + 1}
              page={page}
              onChange={(_, value) => setPage(value)}
              sx={{ display: 'flex', justifyContent: 'center' }}
            />
            <Typography
              variant='captionSmall'
              sx={{ display: 'flex', justifyContent: 'center' }}>
              Showing results {(page - 1) * itemsPerPage + 1} -{' '}
              {Math.min(page * itemsPerPage, count)} out of {count}
            </Typography>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -25 }}
      animate={{ translateX: 0, opacity: 1, from: 0, to: 1 }}
      transition={{ delay: delay || 0.1, ease: 'easeIn' }}>
      <Accordion expanded={expanded} onChange={onChange} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction='row' spacing={1} alignItems='center'>
            {icon}
            <Stack direction='row' spacing={1} alignItems='baseline'>
              <Typography variant='headlineSmall'>{title}</Typography>
              {getSummary()}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ minHeight: 300 }}>
          {getDetails()}
        </AccordionDetails>
      </Accordion>
    </motion.div>
  )
}

export default ResultAccordion
