import { Button, Grid, TextField } from '@northvolt/ui'
import type { AttributeCollection } from 'client/model'
import { useUpdateAttributeCollection } from 'client/wattson-client'
import AttributeSearchAndSelect from 'components/Attribute/AttributeSearchAndSelect'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const EditAttributeCollection = ({
  currentAttributeCollection,
}: {
  currentAttributeCollection: AttributeCollection | undefined
}) => {
  if (!currentAttributeCollection) {
    return null
  }

  const navigate = useNavigate()
  const [attrColl, setAttrColl] = useState<AttributeCollection>(
    currentAttributeCollection,
  )
  const [selectedAttributes, setSelectedAttributes] = useState<
    AttributeCollection['attributes']
  >(currentAttributeCollection.attributes)
  const attrCollMutation = useUpdateAttributeCollection()

  const canSubmit =
    attrColl.name.length > 0 &&
    attrColl.id &&
    (attrColl.description ?? '').length > 0 &&
    (attrColl.attributes ?? []).length > 0

  useEffect(() => {
    if (attrCollMutation.isSuccess) {
      navigate(`/atlas/attribute-collections/${attrCollMutation.data.data.id}`)
    }
  }, [attrCollMutation.status])

  useEffect(() => {
    if (attrColl.attributes) {
      console.log('Selected Attributes: ', attrColl.attributes)
      setAttrColl({
        ...attrColl,
        attributes: attrColl.attributes,
      })
    }
  }, [attrColl.attributes])

  const handleAttributeSelect = (
    newAttributes: AttributeCollection['attributes'],
  ) => {
    setSelectedAttributes(newAttributes)
    setAttrColl({
      ...attrColl,
      attributes: newAttributes,
    })
  }

  return (
    <Grid container columns={12} spacing={2} sx={{ pt: 2 }}>
      <Grid xs={6}>
        <TextField
          label='Name'
          size='small'
          autoComplete='off'
          value={attrColl.name}
          onChange={e => setAttrColl({ ...attrColl, name: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          label='Description'
          size='small'
          autoComplete='off'
          value={attrColl.description}
          onChange={e =>
            setAttrColl({ ...attrColl, description: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <AttributeSearchAndSelect
          label='Search for Attributes'
          selected={selectedAttributes ?? []}
          setSelected={handleAttributeSelect}
          grain={attrColl.grain}
          multiple
        />
      </Grid>
      <Grid xs={12}>
        <Button
          variant='contained'
          color='primary'
          disabled={!canSubmit}
          onClick={() => {
            console.log('Update Attribute Collection', attrColl)
            attrCollMutation.mutate({
              attributeCollectionId: attrColl.id,
              data: attrColl,
            })
          }}>
          Update Attribute Collection
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditAttributeCollection
