/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */

/**
 * An enumeration.
 */
export type AssistantEnum = typeof AssistantEnum[keyof typeof AssistantEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssistantEnum = {
  restricted_factory_copilot: 'restricted_factory_copilot',
  factory_copilot: 'factory_copilot',
  foundation: 'foundation',
} as const;
