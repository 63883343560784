//@ts-nocheck funky code smell

import { Box, Loader, Stack, Typography, useTheme } from '@northvolt/ui'
import type { AttributeStatistics } from 'client/model'
import { useReadAttributeStatistics } from 'client/wattson-client'
import ErrorCard from 'components/ErrorCard'
import { useEffect, useRef } from 'react'

import * as Plot from '@observablehq/plot'
import { isDateOrTime, isFloat, isString } from 'components/Utils'

export default function AttributeStats({ id }: { id: number }) {
  const plotRef = useRef<any>(null)
  const attrStatsLoader = useReadAttributeStatistics(id, {
    query: { retry: 1 },
  })
  const theme = useTheme()

  useEffect(() => {
    if (plotRef.current && attrStatsLoader.data?.data) {
      const stats = attrStatsLoader.data.data

      const data1 = [
        {
          x: `${stats.attribute.grain}s with Attribute`,
          y: stats.count_value,
        },
      ]
      const data2 = [
        {
          x: `${stats.attribute.grain}s without Attribute`,
          y: stats.count_null,
        },
      ]
      const data3 = [{ x: 'Distinct Values', y: stats.count_distinct }]

      const plot = Plot.plot({
        marginLeft: 80,
        x: {
          label: '',
        },
        y: {
          label: 'Count',
          grid: true,
        },
        marks: [
          Plot.ruleY([0]),
          Plot.barY(data1, {
            x: 'x',
            y: 'y',
            fill: theme.palette.grey[400],
            stroke: 'black',
          }),
          Plot.barY(data2, {
            x: 'x',
            y: 'y',
            fill: theme.palette.grey[500],
            stroke: 'black',
          }),
          Plot.barY(data3, {
            x: 'x',
            y: 'y',
            fill: theme.palette.grey[600],
            stroke: 'black',
          }),

          Plot.text(data1, { x: 'x', y: 'y', text: d => d.y, dy: -8 }),
          Plot.text(data2, { x: 'x', y: 'y', text: d => d.y, dy: -8 }),
          Plot.text(data3, { x: 'x', y: 'y', text: d => d.y, dy: -8 }),
        ],
      })
      plotRef.current.appendChild(plot)
      return () => plot.remove()
    }
  }, [attrStatsLoader, plotRef])

  const getValueSection = (stats: AttributeStatistics) => {
    if (stats.string_distinct_values) {
      return (
        <Box maxHeight={200} overflow='auto'>
          {stats.string_distinct_values?.sort().map((value, index) => (
            <Typography key={index} variant='captionSmall'>
              {index !== 0 ? ' • ' : ''}
              {value}
            </Typography>
          ))}
        </Box>
      )
    }
    if (stats.count_value === 0) {
      return (
        <Typography variant='captionSmall' color='text.secondary'>
          No values
        </Typography>
      )
    }

    const bounds = getBounds(stats)
    return (
      <Stack direction='row' spacing={1}>
        <Typography variant='captionSmall' color='text.secondary'>
          Min: {bounds[0]}
        </Typography>
        <Typography variant='captionSmall' color='text.secondary'>
          Max: {bounds[1]}
        </Typography>
      </Stack>
    )
  }

  if (attrStatsLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (attrStatsLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={
          attrStatsLoader.error?.response?.data?.detail?.toString() ||
          'Statistics not found'
        }
      />
    )
  }
  if (attrStatsLoader.data?.data) {
    const stats = attrStatsLoader.data.data

    return (
      <Box sx={{ m: 3 }}>
        <Box ref={plotRef} />
        <Box>
          <Typography variant='subtitleSmall' sx={{ mt: 2 }}>
            Values
          </Typography>
          {getValueSection(stats)}
        </Box>
      </Box>
    )
  }
}

const getBounds = (stats: AttributeStatistics) => {
  try {
    if (isFloat(stats.attribute)) {
      return [
        stats?.double_min?.toLocaleString(),
        stats?.double_max?.toLocaleString(),
      ]
    }
    if (isInteger(stats?.attribute)) {
      return [stats.long_min.toLocaleString(), stats.long_max.toLocaleString()]
    }
    if (isDateOrTime(stats?.attribute)) {
      return [
        reformatDateString(stats.datetime_min),
        reformatDateString(stats.datetime_max),
      ]
    }
    if (isString(stats?.attribute)) {
      return [stats.string_min, stats.string_max]
    }
  } catch (e) {
    return [undefined, undefined]
  }
  return [undefined, undefined]
}
