import { useEffect, useState } from 'react'

import {
  type AttributeCollectionLight,
  type DatasetCreate,
  MaterialType,
  type SampleLight,
} from 'client/model'

import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormControl, InputLabel, Select } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@northvolt/ui'
import { useCreateDataset } from 'client/wattson-client'
import { allowedGrains } from 'components/Utils'
import { useNavigate } from 'react-router-dom'
import AttributeCollectionSearchAndSelect from '../AttributeCollection/AttributeCollectionSearchAndSelect'
import NewAttributeCollection from '../AttributeCollection/NewAttributeCollection'
import NewSample from '../Sample/NewSample'
import SampleSearchAndSelect from '../Sample/SampleSearchAndSelect'

// TODO: This thing is too complex, try some form validation library or something
const NewDataset: React.FC = () => {
  const navigate = useNavigate()
  const [selectedAttributeCollections, setSelectedAttributeCollections] =
    useState<AttributeCollectionLight[]>([])
  const [selectedSamples, setSelectedSamples] = useState<SampleLight[]>([])

  const initialDataset: DatasetCreate = {
    grain: MaterialType.Prismatic_Cell,
    name: '',
  }

  const [dataset, setDataset] = useState<DatasetCreate>(initialDataset)
  const datasetMutation = useCreateDataset()

  const canSubmit =
    dataset.name.length > 0 &&
    dataset.grain &&
    (dataset.attribute_collection_ids?.length ?? 0) > 0 &&
    (dataset.sample_ids?.length ?? 0) > 0

  const [collectionExpanded, setCollectionExpanded] = useState<boolean>(false)
  const [sampleExpanded, setSampleExpanded] = useState<boolean>(false)
  const handleCollectionExpand = () => {
    setCollectionExpanded(!collectionExpanded)
  }
  const handleSampleExpand = () => {
    setSampleExpanded(!sampleExpanded)
  }

  useEffect(() => {
    if (datasetMutation.isSuccess) {
      navigate(`/atlas/datasets/${datasetMutation.data.data.id}`)
    }
  }, [datasetMutation.status])

  useEffect(() => {
    if (selectedAttributeCollections) {
      setDataset({
        ...dataset,
        attribute_collection_ids: selectedAttributeCollections.map(
          attrCol => attrCol.id,
        ),
      })
      setCollectionExpanded(false)
    }
  }, [selectedAttributeCollections])

  useEffect(() => {
    if (selectedSamples) {
      setDataset({
        ...dataset,
        sample_ids: selectedSamples.map(sample => sample.id),
      })
      setSampleExpanded(false)
    }
  }, [selectedSamples])

  return (
    <Grid container columns={12} spacing={2} sx={{ pt: 2 }}>
      <Grid xs={6}>
        <FormControl fullWidth>
          <InputLabel id='grain-label'>Grain</InputLabel>
          <Select
            id='grain'
            label='Grain'
            labelId='grain-label'
            size='small'
            value={dataset?.grain ? dataset.grain : MaterialType.Prismatic_Cell}
            onChange={e =>
              setDataset({ ...dataset, grain: e.target.value as MaterialType })
            }
            fullWidth
            required>
            {Object.values(MaterialType).map(materialType => (
              <MenuItem
                key={materialType}
                value={materialType}
                disabled={!allowedGrains.includes(materialType)}>
                {materialType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <TextField
          label='Name'
          size='small'
          autoComplete='off'
          value={dataset?.name ? dataset.name : ''}
          fullWidth
          required
          onChange={e => setDataset({ ...dataset, name: e.target.value })}
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          label='Description'
          size='small'
          multiline
          required
          autoComplete='off'
          rows={4}
          value={dataset?.description ? dataset.description : ''}
          onChange={e =>
            setDataset({ ...dataset, description: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <Stack spacing={2} border={0.5} borderRadius={1} p={2}>
          <Typography variant='headlineSmall'>Attribute Collection</Typography>
          <AttributeCollectionSearchAndSelect
            label={'Type to Search for Attribute Collections'}
            selectedAttributeCollections={selectedAttributeCollections}
            setSelectedAttributeCollections={setSelectedAttributeCollections}
            grain={dataset.grain}
            multiple={true}
          />
          <Accordion
            title='New Collection'
            expanded={collectionExpanded}
            disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={handleCollectionExpand}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AddIcon />
                <Typography variant='subtitleMedium'>
                  Create New Collection
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <NewAttributeCollection
                attributeCollections={selectedAttributeCollections}
                setAttributeCollections={setSelectedAttributeCollections}
                openOnCreate={false}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Stack spacing={2} border={0.5} borderRadius={1} p={2}>
          <Typography variant='headlineSmall'>Samples</Typography>
          <SampleSearchAndSelect
            selectedSamples={selectedSamples}
            setSelectedSamples={setSelectedSamples}
            grain={dataset.grain}
            multiple={true}
          />
          <Accordion
            title='New Sample'
            expanded={sampleExpanded}
            disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={handleSampleExpand}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AddIcon />
                <Typography variant='subtitleMedium'>
                  Create New Sample
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <NewSample
                samples={selectedSamples}
                setSamples={setSelectedSamples}
                openOnCreate={false}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Button
          variant='contained'
          color='primary'
          disabled={!canSubmit}
          onClick={() => {
            datasetMutation.mutate({ data: dataset })
          }}>
          Create Dataset
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewDataset
